/**
 * ------------------------------------------------------------------------
 * Loop
 * (c) Torus Kit
 * ------------------------------------------------------------------------
 */

import { PREDEFINED } from "./fx.globals";
import TORUS from "./namespace";

import {
  callFunction,
  getIterableElement,
  initClass,
  getProperties,
  getValueForCurrentResolution,
  getResolutions,
  optimizeAttribute,
  REFRESH_ON_RESIZE,
  onLoad,
} from "./util";

TORUS.Loop = class {
  constructor(element) {
    this.element = element;
    this.attributes = optimizeAttribute(this.element.getAttribute("data-tor-loop"))

    // this.setLoop();
  }

  setLoop() {
    let loops = [];
    let action;

    for (const attribute of this.attributes.split(" ")) {
      let GP = getProperties(attribute);

      if ((/(\()*(\))/g).test(attribute)) {
        let resolutions = {};
        let exec;
        let unit;

        if (/(.*?)\(/.exec(attribute)) {
          exec = /(.*?)\(/.exec(attribute)[1];
        }
        else {
          console.error(`Unknown loop attribute: ${attribute}`);
          return;
        }

        unit = GP.unit ? GP.unit : "";

        resolutions = getValueForCurrentResolution(getResolutions({ property: (/\((.*?)\)+/gm).exec(attribute)[1], object: resolutions, name: "end" })).end;

        if (!PREDEFINED.includes(GP.value)) {
          switch (exec) {
            case "duration":
              resolutions ? this.element.style.setProperty(`--tor-loop-duration`, `${resolutions}${unit}`) : this.element.style.removeProperty("--tor-loop-duration");
              break;

            default:
              resolutions ? this.element.style.setProperty(`--tor-loop-${GP.name}-value`, `${resolutions}${unit}`) : this.element.style.removeProperty(`--tor-loop-${GP.name}-value`);
              break;
          }
        }

        if (GP.cssPropertyType !== "duration") {
          loops.push(`loop-${GP.cssPropertyType.replace(".", "-")}`);
        }
      }

      if(!action) {
        action = GP.action;
      }

    }

    /**
     * WebKit bug
     * Does not update animation when using CSS variables
     */
    if(!action) {
      this.element.style.setProperty("animation-name", "none");

      setTimeout(() => {
        this.element.style.setProperty("animation-name", loops.join(","));
      }, 10);
    }
  }

  refresh() {
    this.setLoop();
  }

  /**
   * ------------------------------------------------------------------------
   * Public methods
   * ------------------------------------------------------------------------
   */

  static refresh(elements) {
    callFunction({elements: getIterableElement(elements || "[data-tor-loop]"), object: "Loop", fn: "refresh"});
  }

  /**
   * ------------------------------------------------------------------------
   * Initialize
   * ------------------------------------------------------------------------
   */

  static init(elements) {
    elements = getIterableElement(elements || "[data-tor-loop]");
    initClass({name: "Loop", elements: elements});

    /** Batch */
    this.refresh(elements);

    REFRESH_ON_RESIZE.loop = true;
  }
}

// TORUS.Loop.init();
onLoad("Loop", "DOMContentLoaded");

export default TORUS.Loop;
