/**
 * ------------------------------------------------------------------------
 * Slider
 * (c) Torus Kit
 * ------------------------------------------------------------------------
 */

import TORUS from "./namespace";

import {
  getIterableElement,
  initClass,
  callFunction,
  optimizeAttribute,
  getProperties,
  getValueForCurrentResolution,
  REFRESH_ON_RESIZE,
  wrapElement,
  onLoad,
} from "./util";

TORUS.Slider = class {

  constructor(element, options) {
    /** Main parent element */
    this.parent = element;

    this.setOptions(options);
    this.setDimensions();
    this.wrapItems();

    if(!this.slider || !this.itemsElements) {
      return;
    }

    /**
     * Defaults
     */

    this.activeSlide = 0;                     // Default active slide
    this.isDown = false;                      // If pointer (mouse or touch) is pressed
    this.dragging = false;                    // If user drags the slider by mouse our touch event
    this.scrolling = false;                   // Slider is scrolling/translating
    this.differenceX = 0;                     // How fast pointer moves. Calculated when pointer is down (_onDown())
    this.distanceX = 0;                       // How far the slider moves while pointer is down (_onDown())
    this.startX = 0;                          // Current start position when pointer is down (_onDown())

    this.slider.translateLeft = 0;            // Amount of CSS translateX in px
    this.lastTranslateLeft = 0;               // The last slider position form the left
    this.slider.translateTop = 0;             // Amount of CSS translateY in px
    this.lastTranslateTop = 0;                // The last slider position form the top
    this.offsetLeft = this.parent.offsetLeft; // Element's offsetLeft from the screen edge
    this.offsetTop = this.parent.offsetTop;   // Element's offsetLeft from the screen edge
    this.leftBoundsReached = true;            // If the slider reaches the first slide (start). True when initialized
    this.rightBoundsReached = false;          // If the slider reaches the last slide (end)
    this.topBoundsReached = true;
    this.bottomBoundsReached = false;

    this.allItemsCount = this.itemsElements.length;		// How many .slide-item elements slider contains

    /** Firefox hack to prevent from returning 0 for some properties (clientWidth) immediately after document load */
    setTimeout(() => {
      this.setProperties();
      this.setNavigation();
      this.addEventListeners();

      /** Set .active class to currently visible items */
      this.setActiveItems(true);

      /** Set .show class to currently visible items */
      this.setShowItems();
    }, 10);

  }

  //
  // ------------------------------------------------------------------------
  // Set slider dimensions
  // ------------------------------------------------------------------------
  //

  setDimensions() {
    if(!this.options.autoHeight) {
      this.parent.style.setProperty("--tor-slider-height", `${this.parent.clientHeight}px`);
    }
    this.parent.style.setProperty("--tor-slider-width", `${this.parent.clientWidth}px`);
  }

  /**
   * ------------------------------------------------------------------------
   * Set defaults
   * ------------------------------------------------------------------------
   */

  setDefaults() {
    if(!this.slider || !this.itemsElements) {
      return;
    }

    /**
     * Defaults
     */

    this.activeSlide = 0;                     // Default active slide
    this.isDown = false;                      // If pointer (mouse or touch) is pressed
    this.dragging = false;                    // If user drags the slider by mouse our touch event
    this.scrolling = false;                   // Slider is scrolling/translating
    this.differenceX = 0;                     // How fast pointer moves. Calculated when pointer is down (_onDown())
    this.distanceX = 0;                       // How far the slider moves while pointer is down (_onDown())
    this.startX = 0;                          // Current start position when pointer is down (_onDown())

    this.slider.translateLeft = 0;            // Amount of CSS translateX in px
    this.lastTranslateLeft = 0;               // The last slider position form the left
    this.slider.translateTop = 0;             // Amount of CSS translateY in px
    this.lastTranslateTop = 0;                // The last slider position form the top
    this.offsetLeft = this.parent.offsetLeft; // Element's offsetLeft from the screen edge
    this.offsetTop = this.parent.offsetTop;   // Element's offsetLeft from the screen edge
    this.leftBoundsReached = true;            // If the slider reaches the first slide (start). True when initialized
    this.rightBoundsReached = false;          // If the slider reaches the last slide (end)
    this.topBoundsReached = true;
    this.bottomBoundsReached = false;

    this.allItemsCount = this.itemsElements.length;		// How many .slide-item elements slider contains
  }

  //
  // ------------------------------------------------------------------------
  // Wrap inner slider elements into corresponding classes
  // ------------------------------------------------------------------------
  //

  wrapItems() {
    /** Wrap first child into `.slider-item` wrapper (if it's not already) */
    for (const item of this.parent.children) {
      if (!item.classList.value.includes("slider-item")) {
        wrapElement(item, "div", "slider-item");
      }
    }

    /** .slider-item elements */
    this.itemsElements = this.parent.querySelectorAll(".slider-item");

    /** Wrap all `.slider-item` elements into `.slider-items` parent */
    wrapElement(this.itemsElements, this.parent, "slider-items");

    /** Select parent of all .slider-item elements */
    this.slider = this.parent.querySelector(".slider-items");

    /** Wrap the `.slider-items` elements into `.slider-inner` parent */
    wrapElement(this.slider, "div", "slider-inner");

    /** Select `.slider-inner` element */
    this.sliderInner = this.parent.querySelector(".slider-inner");
  }

  /**
   * ------------------------------------------------------------------------
   * Set options
   * ------------------------------------------------------------------------
   */

  setOptions(options) {

    /** Options defined in [data-tor-slider] attribute */
    this.dataOptions = optimizeAttribute(this.parent.getAttribute("data-tor-slider")).split(" ");

    /**
     * Default Options
     */

    this.defaults = {
      count: 1,                   // Number of visible items per slide
      margin: 0,                  // Margin (space) between the items
      pullArea: 10,               // If the slider doesn't exceed this area when dragging, it reverses back
      stretchOnDrag: true,        // Stretch space between items on bounds when dragging
      stretchOnClick: false,      // Stretch space between items on bounds on click next/prev button
      addTrigger: false,          // Add parent `[data-tor-fx-trigger]` to `.slider-item`
      slide: true,                // Enable slider sliding
      vertical: false,
      drag: true,
    }

    /** Merge user options with defaults */
    this.options = Object.assign(this.defaults, options);

    /** If there are additional options defined in `[data-tor-slider]`, create them or replace the default ones */
    for(const option of this.dataOptions) {
      /**
       * Grab all values that has `()`
       */
      if((/(\()*(\))/g).test(option)) {
        let GP = getProperties(option);
        let value = getValueForCurrentResolution(GP.value).end;
        this.options[GP.activeName.toCamelCase()] = value;
      }
    }
  }

  /**
   * ------------------------------------------------------------------------
   * Set properties
   * ------------------------------------------------------------------------
   */

  setProperties() {
    let lastOffsetTop = 0;

    this.parent.style.setProperty("--tor-slides-margin", `${this.options.margin}px`);
    this.parent.style.setProperty("--tor-slides-count", this.options.count);

    this.parent.classList.add("tor-done");

    this.sliderFullWidth = 0;

    /** Object that contains .slider-item nodes */
    this.items = this.items || {};

    /** Loop trough all .slider-item elements and define the element properties */
    for(let [i, item] of Object.entries(this.itemsElements)) {
      this.items[i] = this.items[i] || {};	// Create an object
      this.items[i].element = item;			    // Item element
      this.items[i].id = Number(i);			    // Number of the slide. Starting from 0

      let slideWidth = this.items[i].element.clientWidth;

      this.items[i].positionLeft = Number(i) + 1;                   // Position number of the item from the left: 1st, 2nd ... n+1
      this.items[i].positionRight = this.allItemsCount - Number(i); // Position number of the item from the right: n-1 ... 2nd, 1st
			this.sliderFullWidth += slideWidth;        // Counts all items widths together to get the slider width

      /** Item offset from the left side of the slider */
      this.items[i].offsetLeft = Number(i * (slideWidth + this.options.margin));
			// this.items[i].offsetTop = Number(i * (this.items[i].element.clientHeight + this.options.margin));

      this.items[i].offsetTop = lastOffsetTop;
      lastOffsetTop = lastOffsetTop + this.items[i].element.clientHeight + this.options.margin;

      /** Set data attribute for the single .slider-item with the unique ID */
      this.items[i].element.setAttribute("data-tor-slider-item-id", this.items[i].id);

      /** Add parent fx triggers to `.slider-item` */
      if(this.options.addTrigger) {
        this.items[i].element.setAttribute("data-tor-fx-trigger", this.options.addTrigger.replace(/,+/g, " "));
      }

      /** If item isn't the first, define the left pullArea */
      if(item.previousElementSibling) {
        this.items[i].pullAreaLeft_Start = this.items[i].offsetLeft - item.previousElementSibling.clientWidth - this.options.margin + this.options.pullArea;
        this.items[i].pullAreaLeft_End = this.items[i].offsetLeft + this.options.pullArea;

        this.items[i].pullAreaTop_Start = this.items[i].offsetTop - item.previousElementSibling.clientHeight - this.options.margin + this.options.pullArea;
        this.items[i].pullAreaTop_End = this.items[i].offsetTop + this.options.pullArea;
      }
      else {
        this.items[i].pullAreaLeft_Start = 0;
        this.items[i].pullAreaLeft_End = this.options.pullArea;

        this.items[i].pullAreaTop_Start = 0;
        this.items[i].pullAreaTop_End = this.options.pullArea;
      }

      /** Defined the right pullArea */
      this.items[i].pullAreaRight_Start = this.items[i].offsetLeft + slideWidth + this.options.margin - this.options.pullArea;
      this.items[i].pullAreaRight_End = this.items[i].offsetLeft - this.options.pullArea;

      this.items[i].pullAreaBottom_Start = this.items[i].offsetTop + this.items[i].element.clientHeight + this.options.margin - this.options.pullArea;
			this.items[i].pullAreaBottom_End = this.items[i].offsetTop - this.options.pullArea;

			// this.items[i].element.style.setProperty("--tor-item-height", `${this.items[i].element.clientHeight}px`);
			this.items[i].height = this.items[i].element.clientHeight;
    }

    this.sliderClientWidth = this.slider.clientWidth;
    this.sliderClientHeight = this.slider.clientHeight;
    this.sliderScrollWidth = this.slider.scrollWidth;
    this.sliderScrollHeight = this.slider.scrollHeight;

    /** How far can slider move (translate) */
    this.maxScrollX = this.options.slide ? this.slider.scrollWidth - this.slider.clientWidth : 0;
    this.maxScrollY = this.options.slide ? this.slider.scrollHeight - this.slider.clientHeight : 0;

    /** Set width for slider drag area */
		if (!this.options.vertical) {
      this.parent.style.setProperty("--tor-slider-area-width", `${this.sliderFullWidth}px`);
    }

    if(this.options.addTrigger) {
      // TORUS.Fx.refresh();
    }

  }

  /**
   * ------------------------------------------------------------------------
   * Create navigation
   * ------------------------------------------------------------------------
   */

  setNavigation() {
    this.navigation = this.navigation || {};	// Navigation object
    this.createControls();						// Next/Prev controls
    this.createIndicators();					// Dot indicators
  }

  /**
   * ------------------------------------------------------------------------
   * Event listeners
   * ------------------------------------------------------------------------
   */

  addEventListeners() {
    // document.addEventListener("pointerup", this._onUp.bind(this));
    // document.addEventListener("pointermove", this._onMove.bind(this));
    // this.slider.addEventListener("pointerdown", this._onDown.bind(this));

    document.addEventListener("mouseup", this._onUp.bind(this));
    document.addEventListener("touchend", this._onUp.bind(this), {passive: true});
    document.addEventListener("mousemove", this._onMove.bind(this));
    document.addEventListener("touchmove", this._onMove.bind(this), {passive: true});

    if (this.options.drag) {
      this.slider.addEventListener("mousedown", this._onDown.bind(this));
      this.slider.addEventListener("touchstart", this._onDown.bind(this), { passive: true });
    }

    this.slider.addEventListener("dragstart", this._onDragStart.bind(this));
    this.slider.addEventListener("click", this._onClickSlider.bind(this));
    this.slider.addEventListener("transitionend", this._onTransitionEnd.bind(this), {passive: true});
  }

  /**
   * ------------------------------------------------------------------------
   * On pointer down (mouse pressed or touch start)
   * ------------------------------------------------------------------------
   */

  _onDown(e) {

    if(!this.options.slide || !this.options.drag) {
      return;
    }

    window.requestAnimationFrame(() => {
      this.isDown = true;

      /** Determinate the main pointer device */
      if(e.pageX) {
        this.pointerDevice = "pointer";
      }
      if(e.touches) {
        this.pointerDevice = "touch";
      }

      /** Pointer position immediately on pointer down */
      this.startX = this._page(e).x + this.slider.translateLeft;
      this.startY = this._page(e).y + this.slider.translateTop;

      /** How far the slider moves while pointer is down */
      this.distanceX = 0;
      this.distanceY = 0;

      /** Do white pointer is down */
      let count = function () {
        if(this.isDown) {
          /** Dynamic difference. Used to calculate "bounce" from slider edge. If pointer is not moving, difference is 0 */
          this.differenceX = this.slider.translateLeft - this.lastTranslateLeft;
          this.lastTranslateLeft = this.slider.translateLeft;

          this.differenceY = this.slider.translateTop - this.lastTranslateTop;
          this.lastTranslateTop = this.slider.translateTop;

          this.parent.classList.add("dragging");

          window.requestAnimationFrame(count);
        }
      }.bind(this);

      count();

    });
  }

  /**
   * ------------------------------------------------------------------------
   * On pointer move
   * ------------------------------------------------------------------------
   */

  _onMove(e) {

    if (!this.options.slide || !this.options.drag) {
      return;
    }

    window.requestAnimationFrame(() => {
      if (!this.isDown) {
        return;
      }

      this.dragging = true;
      this.dragMove = true;

      /** Original startX minus current startX */
      this.distanceX = (this.startX - (this._page(e).x + this.slider.translateLeft)) / 2;
      this.distanceY = (this.startY - (this._page(e).y + this.slider.translateTop)) / 2;

      /** (+) <--- Slider moving to left */
      if (this.distanceX > 0 && this.rightBoundsReached) {
        this.distanceX = 0;
      }

      /** (-) ---> Slider moving to right */
      if (this.distanceX < 0 && this.leftBoundsReached) {
        this.distanceX = 0;
      }

      /** (+) Slider moving to top */
      if (this.distanceY > 0 && this.bottomBoundsReached) {
        this.distanceY = 0;
      }

      /** (-) Slider moving to bottom */
      if (this.distanceY < 0 && this.topBoundsReached) {
        this.distanceY = 0;
      }

      /** Add current traveled distance into parent slider CSS translateX */
      this.slider.translateLeft += this.distanceX;
      this.slider.translateTop += this.distanceY;

      if (this.options.vertical) {
        this.slider.style.setProperty("transform", `translate3d(0px, ${-this.slider.translateTop}px, 0px)`);
      }
      else {
        this.slider.style.setProperty("transform", `translate3d(${-this.slider.translateLeft}px, 0px, 0px)`);
      }

      /** Check if left or right bounds are reached */
      this.checkBounds();

      /**
       * Expand items when bounds reached
       */

      /** Do only when dragging */
      if (this.dragging) {
        if (this.options.vertical) {
          if (this.topBoundsReached) {
            this.slider.translateTop = 0;
            this.slider.style.setProperty("transform", `translate3d(0px, ${this.slider.translateTop}px, 0px)`);
          }

          if (this.bottomBoundsReached) {
            this.maxScrollY = this.slider.scrollHeight - this.slider.clientHeight;
            this.slider.translateTop = this.maxScrollY;
            this.slider.style.setProperty("transform", `translate3d(0px, ${-this.slider.translateTop}px, 0px)`);
          }
        }
        else {
        /** Do only when left bounds reached (translateLeft is 0) and slider is scrolling from the left bound */
          if (this.leftBoundsReached) {
            this.slider.translateLeft = 0;
            this.slider.style.setProperty("transform", `translate3d(${this.slider.translateLeft}px, 0px, 0px)`);

            if (this.options.stretchOnDrag) {
              this.stretchOnBounds("positionLeft", ((this.startX - this._page(e).x) / 500), false);
            }
          }

          /** Do only when right bounds reached (translateLeft is greater than slider width) and slider is scrolling from the right bound */
          if (this.rightBoundsReached) {
            this.slider.translateLeft = this.maxScrollX;
            this.slider.style.setProperty("transform", `translate3d(${-this.slider.translateLeft}px, 0px, 0px)`);

            if (this.options.stretchOnDrag) {
              this.stretchOnBounds("positionRight", ((this.startX - (this._page(e).x + this.slider.translateLeft)) / 500), false);
            }
          }
        }
      }

    });
  }

  /**
   * ------------------------------------------------------------------------
   * On pointer up (mouse released or touch end)
   * ------------------------------------------------------------------------
   */

  _onUp() {

    if (!this.options.slide || !this.options.drag) {
      return;
    }

    window.requestAnimationFrame(() => {
      this.isDown = false;

      if(this.dragging) {
        /**
         * Slider snap
         * Add automatic scroll easing to the nearest item's snap area
         */

        if (this.options.vertical) {
          /** +1 Direction: Slider is dragged from bottom to top */
          if (this.distanceY >= 0) {
            /** Find corresponding item with top pull area that includes current translateTop */
            for (let i in this.items) {
              if (this.slider.translateTop >= this.items[i].pullAreaTop_Start && this.slider.translateTop <= this.items[i].pullAreaTop_End) {
                this.translate(this.items[this.items[i].id].offsetTop);
              }
            }
          }

          /** -1 Direction: Slider is dragged from top to bottom */
          if (this.distanceY < 0) {

            /** Find corresponding item with bottom pull area that includes current translateTop */
            for (let i in this.items) {
              if (this.slider.translateTop <= this.items[i].pullAreaBottom_Start && this.slider.translateTop >= this.items[i].pullAreaBottom_End) {
                this.translate(this.items[this.items[i].id].offsetTop);
              }
            }

          }
        }
        else {
          /** +1 Direction: Slider is dragged from right to left */
          if (this.distanceX >= 0) {

            /** Find corresponding item with left pull area that includes current translateLeft */
            for (let i in this.items) {
              if (this.slider.translateLeft >= this.items[i].pullAreaLeft_Start && this.slider.translateLeft <= this.items[i].pullAreaLeft_End) {
                this.translate(this.items[this.items[i].id].offsetLeft);
              }
            }
          }

          /** -1 Direction: Slider is dragged from left to right */
          if (this.distanceX < 0) {

            /** Find corresponding item with right pull area that includes current translateLeft */
            for (let i in this.items) {
              if (this.slider.translateLeft <= this.items[i].pullAreaRight_Start && this.slider.translateLeft >= this.items[i].pullAreaRight_End) {
                this.translate(this.items[this.items[i].id].offsetLeft);
              }
            }
          }
        }

        /** Set .active class to currently visible items */
        this.setActiveItems();

        /** Revert all transforms (if there is any) back to 0px on mouse button release */
        if(this.options.vertical) {
          if (this.topBoundsReached || this.bottomBoundsReached) {
            for (let i in this.items) {
              this.items[i].element.style.setProperty("transform", "translate3d(0px, 0px, 0px)");
            }
          }
        }
        else {
          if (this.leftBoundsReached || this.rightBoundsReached) {
            for (let i in this.items) {
              this.items[i].element.style.setProperty("transform", "translate3d(0px, 0px, 0px)");
            }
          }
        }

      }

      /** Set dragMove (slider dragging and moving together) to false
       * Used in _onClickSlider() to prevent from link opening on pointer end (mouse up)
       * if item contains <a href> element
       */
      setTimeout(() => {
        this.dragMove = false;
      }, 50);

      this.parent.classList.remove("dragging");
      this.dragging = false;
    });
  }


  /**
   * ------------------------------------------------------------------------
   * Click events
   * ------------------------------------------------------------------------
   */

  /**
   * Click on Previous navigation button
   */

  _onClickPrev(e) {
    e.preventDefault();

    if(this.scrolling) {
      return;
    }

    this.slideTo("prev");
  }

  /**
   * Click on Next navigation button
   */

  _onClickNext(e) {
    e.preventDefault();

    if(this.scrolling) {
      return;
    }

    this.slideTo("next");
  }

  /**
   * Click on dot indicators
   */

  _onClickIndicators(e) {
    e.preventDefault();

    /** Match only [data-tor-slide-to] element as click target */
    if(e.target.matches("[data-tor-slide-to]")) {
      this.slideTo(Number(e.target.getAttribute("data-tor-slide-to")));
    }
  }

  /**
   * Click on whole slider (parent slider or any inner element)
   */

  _onClickSlider(e) {
    /** Disable any click events while pointer is dragging and moving a slider to prevent from link opening */
    if(this.dragMove) {
      e.preventDefault();
      e.stopPropagation();
    }
  }

  /**
   * ------------------------------------------------------------------------
   * On drag start
   *
   * Disable browser dragging behavior when pointer starts to drag a slider
   * ------------------------------------------------------------------------
   */

  _onDragStart(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  /**
   * ------------------------------------------------------------------------
   * On transition end
   * ------------------------------------------------------------------------
   */

  _onTransitionEnd(e) {
    window.requestAnimationFrame(() => {
      /** Wait for the transition end on `.slider-items` element */
      if(e.target.classList.contains("slider-items") && e.propertyName.match("transform")) {
        this.sliding = false;
        this.parent.classList.remove("sliding");
        this.parent.classList.remove("translating");
        this.setShowItems();
      }
    });
  }

  /**
   * ------------------------------------------------------------------------
   * Check bounds
   * ------------------------------------------------------------------------
   */

  checkBounds() {
    this.leftBoundsReached = this.slider.translateLeft <= 0 ? true : false;
    this.rightBoundsReached = this.sliderClientWidth + this.slider.translateLeft >= this.sliderScrollWidth - 1 ? true : false;

    this.topBoundsReached = this.slider.translateTop <= 0 ? true : false;
    this.bottomBoundsReached = this.sliderClientHeight + this.slider.translateTop >= this.sliderScrollHeight - 1 ? true : false;
  }

  /**
   * ------------------------------------------------------------------------
   * Controls
   * ------------------------------------------------------------------------
   */

  /**
   * Create Next/prev controls
   */

  createControls() {
    if(!this.options.controls) {
      return;
    }

    if(this.navigation.controlsElement) {
      this.navigation.controlsElement.remove();
    }

    let controls = {
      prev: "Prev",
      next : "Next",
    }

    this.navigation.controlsElement = document.createElement("nav");
    this.navigation.controlsElement.classList.add("slider-controls");
    this.sliderInner.appendChild(this.navigation.controlsElement);

    for(let [key, value] of Object.entries(controls)) {
      let control;

      control = document.createElement("a");
      control.addEventListener("click", this[`_onClick${value}`].bind(this));
      control.classList.add(`slider-${key}`, "tor-slider-internal");
      control.setAttribute("role", "button");
      control.setAttribute("aria-label", `Slider ${key} button`);
      control.setAttribute("href", "#");
      control.setAttribute("data-tor-slide-to", key);
      this.navigation.controlsElement.appendChild(control);
    }
  }

  /**
   * Create dot indicators
   */

  createIndicators() {
    if(!this.options.indicators) {
      return;
    }

    if(this.navigation.indicatorsElement) {
      this.navigation.indicatorsElement.remove();
    }

    this.navigation.indicatorsElement = document.createElement("nav");
    this.navigation.indicatorsElement.classList.add("slider-indicators");
    this.parent.appendChild(this.navigation.indicatorsElement);
    this.navigation.indicatorsElement.addEventListener("click", this._onClickIndicators.bind(this));
    this.navigation.indicatorsCount = this.allItemsCount / this.options.count;

    for(let i=0; i<this.navigation.indicatorsCount; i++) {
      let indicator;

      indicator = document.createElement("a");
      indicator.setAttribute("href", "#");
      indicator.setAttribute("role", "button");
      indicator.setAttribute("aria-label", `Show the number ${i * this.options.count} slide button`);
      indicator.setAttribute("data-tor-slide-to", i * this.options.count);
      indicator.classList.add("slider-indicator", "tor-slider-internal");
      this.navigation.indicatorsElement.appendChild(indicator);
    }
  }

  /**
   * ------------------------------------------------------------------------
   * Return pageX
   * ------------------------------------------------------------------------
   */

  _page(e) {
    let pageX;
    let pageY;

    switch (this.pointerDevice) {
      case "pointer":
        pageX = e.pageX;
        pageY = e.pageY;
        break;

      case "touch":
        pageX = e.touches[0].pageX;
        pageY = e.touches[0].pageY;
        break;
    }

    // return pageX;
    return {
      x: pageX,
      y: pageY,
    }
  }

  /**
   * ------------------------------------------------------------------------
   * Slide to specific item
   * ------------------------------------------------------------------------
   */

  slideTo(direction) {
    window.requestAnimationFrame(() => {
      let lastSlide = this.activeSlide;

      if(direction === "next") {
        this.distanceX = 0;
        this.distanceY = 0;

        if(this.activeSlide < (this.allItemsCount - this.options.count)) {
          this.parent.classList.add("sliding");
          this.activeSlide++;

          /** Translate the slider to left. Enabled by default */
          if(this.options.slide) {
            // this.translate(this.items[this.activeSlide].offsetLeft);
            // console.log(this.items[this.activeSlide].offsetTop);
            this.options.vertical ? this.translate(this.items[this.activeSlide].offsetTop) : this.translate(this.items[this.activeSlide].offsetLeft);
          }
        }
        else {
          if(this.options.stretchOnClick) {
            this.stretchOnBounds("positionRight", 3, true)
          }
        }
      }

      if(direction === "prev") {
        this.distanceX = 0;

        if(this.activeSlide > 0) {
          this.parent.classList.add("sliding");
          this.activeSlide--;

          /** Translate the slider to right. Enabled by default */
          if(this.options.slide) {
            // this.translate(this.items[this.activeSlide].offsetLeft);
            this.options.vertical ? this.translate(this.items[this.activeSlide].offsetTop) : this.translate(this.items[this.activeSlide].offsetLeft);
          }
        }
        else {
          if(this.options.stretchOnClick) {
            this.stretchOnBounds("positionLeft", -3, true);
          }
        }
      }

      /** SLide to specific item number */
      if(Number(direction) || direction === 0 || direction === "0") {
        // let lastSlide = this.activeSlide;
        direction = Number(direction);
        this.distanceX = 0;
        this.activeSlide = direction;

        /** Only do when user doesn't click on the active dot */
        if(lastSlide !== this.activeSlide) {

          this.parent.classList.add("sliding");

          /** If desired item has offsetLeft greater that maximum scroll, than scroll only to this maximum */
          if (this.items[this.activeSlide]) {

            if (this.options.vertical) {
              if (this.items[this.activeSlide].offsetTop >= this.maxScrollY) {
                this.translate(this.maxScrollY);
              }
              /** Else scroll to desired offsetLeft of the active item */
              else {
                this.translate(this.items[this.activeSlide].offsetTop);
              }
            }
            else {
              if (this.items[this.activeSlide].offsetLeft >= this.maxScrollX) {
                this.translate(this.maxScrollX);
              }
              /** Else scroll to desired offsetLeft of the active item */
              else {
                this.translate(this.items[this.activeSlide].offsetLeft);
              }
            }

          }
          else {
            console.warn("It looks like you are pointing to non-existing slide in this slider:", this.parent)
          }
        }
      }

      // console.log(this.activeSlide, this.items[this.activeSlide].offsetTop, this.items[this.activeSlide].height);

      /** Set .active class immediately */
      if(lastSlide !== this.activeSlide) {
        this.setActiveItems();
      }

      // if(this.options.slide === false) {
        // this.setShowItems();
      // }

    });
  }

  /**
   * ------------------------------------------------------------------------
   * Translate a slider
   * ------------------------------------------------------------------------
   */

  translate(offset) {
    if (this.options.vertical) {
      this.slider.translateTop = offset;
      this.slider.style.setProperty("transform", `translate3d(0px, ${-this.slider.translateTop}px, 0px)`);
    }
    else {
      this.slider.translateLeft = offset;
      this.slider.style.setProperty("transform", `translate3d(${-this.slider.translateLeft}px, 0px, 0px)`);
    }

    if (this.options.vertical) {
      if (!this.topBoundsReached || !this.bottomBoundsReached) {
        this.parent.classList.add("translating");
      }
      if (this.topBoundsReached || this.bottomBoundsReached) {
        this.parent.classList.remove("translating");
      }
    }
    else {
      if (!this.leftBoundsReached || !this.rightBoundsReached) {
        this.parent.classList.add("translating");
      }
      if (this.leftBoundsReached || this.rightBoundsReached) {
        this.parent.classList.remove("translating");
      }
    }

  }

  /**
   * ------------------------------------------------------------------------
   * Set .show class on visible items
   * ------------------------------------------------------------------------
   */

  setShowItems() {
    for(let i in this.items) {
      this.items[i].element.classList.remove("show");
    }

    /** Add .show class to all visible items */
    for(let i=0; i<this.options.count; i++) {
      if((this.activeSlide + i) < this.allItemsCount) {
        this.items[this.activeSlide + i].element.classList.add("show");
      }
    }

    // this.controlsClicked = false;
  }

  /**
   * ------------------------------------------------------------------------
   * Set active items
   * Find visible items based on pull area
   * ------------------------------------------------------------------------
   */

  setActiveItems(recalculate) {
		let maxHeight = [];
    this.sliding = true;

    /** Remove all .active class first */
    for(let i in this.items) {
      this.items[i].element.classList.remove("active");

      if(!this.options.slide) {
        this.items[i].element.classList.remove("show");
      }
    }

    for(let i in this.items) {
      this.items[i].element.classList.remove("active");

      if (this.options.vertical) {
        if (this.slider.translateTop >= this.items[i].pullAreaTop_Start && this.slider.translateTop <= this.items[i].pullAreaTop_End || this.slider.translateTop <= this.items[i].pullAreaBottom_Start && this.slider.translateTop >= this.items[i].pullAreaBottom_End) {
          this.activeSlide = this.items[i].id;
        }
      }
      else {
        if (this.slider.translateLeft >= this.items[i].pullAreaLeft_Start && this.slider.translateLeft <= this.items[i].pullAreaLeft_End || this.slider.translateLeft <= this.items[i].pullAreaRight_Start && this.slider.translateLeft >= this.items[i].pullAreaRight_End) {
          this.activeSlide = this.items[i].id;
        }
      }
    }

    if(recalculate) {
      this.slider.translateLeft = this.items[this.activeSlide].offsetLeft;
      this.slider.style.setProperty("transform", `translate3d(${-this.slider.translateLeft}px, 0px, 0px)`);
    }

    /** Add .active class to all visible items */
    for(let i=0; i<this.options.count; i++) {
      if((this.activeSlide + i) < this.allItemsCount) {

				maxHeight.push(this.items[this.activeSlide + i].height);

        /** Small delay before adding new class so the browser will not apply an `active` state immediately */
        setTimeout(() => {
          this.items[this.activeSlide + i].element.classList.add("active");
        }, 10);

        /** If the sliding is disabled by `slide(false)` */
        if(!this.options.slide) {
          setTimeout(() => {
            this.items[this.activeSlide + i].element.classList.add("show");
          }, 10);
        }
      }
		}

		if (this.options.autoHeight) {
      let sum = 0;
      let margin = (this.options.count - 1) * this.options.margin;

      for (let item of maxHeight) {
        sum = item + sum;
      }

      this.parent.style.setProperty("--tor-slider-height", `${ this.options.vertical ? sum + margin : Math.max(...maxHeight) }px`);
		}

    if(this.options.indicators) {
      for(let indicator of this.navigation.indicatorsElement.querySelectorAll("[data-tor-slide-to]")) {
        indicator.classList.remove("active");
      }

      if(this.activeSlide + this.options.count < this.allItemsCount) {
        this.navigation.indicatorsElement.querySelector(`[data-tor-slide-to="${parseInt(this.activeSlide / this.options.count) * this.options.count}"]`).classList.add("active");
      }
      else {
        this.navigation.indicatorsElement.querySelector(`[data-tor-slide-to]:last-child`).classList.add("active");
      }
    }

  }

  /**
   * Left/right bounds has reached while dragging
   */

  compressOnBounds(distance, direction, autoResetPosition) {
    let t;
    let ease;
    let boundDistance;

    t = (1 - (1 + this.distanceX * (direction))) / 1000;
    ease = TORUS.Util.easing.easeOutQuad(t);
    boundDistance = (distance * (direction)) * ease;

    for(let i in this.items) {
      if(direction === 1) {
        this.items[i].element.style.setProperty("transform", `translate3d(${boundDistance - ((this.items[i].positionLeft*(distance/5))*ease) }px, 0px, 0px)`);
      }
      if(direction === -1) {
        this.items[i].element.style.setProperty("transform", `translate3d(${boundDistance + ((this.items[i].positionRight*(distance/5))*ease) }px, 0px, 0px)`);
      }
    }

    if(autoResetPosition) {
      setTimeout(() => {
        for(let i in this.items) {
          this.items[i].element.style.setProperty("transform", `translate3d(0px, 0px, 0px)`);
        }
      }, 200);
    }
  }

  /**
   * ------------------------------------------------------------------------
   * Stretch on bounds
   *
   * Stretch space between items while dragging when left/right bound has been reached
   * ------------------------------------------------------------------------
   */

  stretchOnBounds(position, value, autoResetPosition) {
    for(let i in this.items) {
      this.items[i].element.style.setProperty("transform", `translate3d(${(-value)*( this.items[i][position] * this.items[i][position])}px, 0px, 0px)`);
    }

    if(autoResetPosition) {
      for(let i in this.items) {
        setTimeout(() => {
          this.items[i].element.style.setProperty("transform", `translate3d(0px, 0px, 0px)`);
        }, 200);
      }
    }
  }

  _refresh() {
    this.setDimensions();
    this.setProperties();
    this.setNavigation();
    this.setActiveItems(true);
    this.setShowItems();
  }

  /**
   * ------------------------------------------------------------------------
   * Public methods
   * ------------------------------------------------------------------------
   */

  /**
   * Refresh slider
   */

  static refresh(elements) {
    callFunction({elements: getIterableElement(elements || ".tor-slider"), object: "Slider", fn: "_refresh"});
  }

  /**
   * Slide to
   */

  static slideTo(elements, direction) {
    callFunction({elements: getIterableElement(elements || ".tor-slider"), object: "Slider", fn: "slideTo", argument: direction});
  }

  /**
   * External controls
   */

  static createExternalControls() {
    for (const control of document.querySelectorAll("[data-tor-slide-to]:not(.tor-slider-internal)")) {
      let targets;

      if (control.hasAttribute("data-tor-slide-target")) {
        targets = document.querySelectorAll(control.getAttribute("data-tor-slide-target"))
      }
      else {
        targets = document.querySelectorAll(/[^\/]+$/.exec(control.href)[0]);
      }
      // let targets = document.querySelectorAll(control.getAttribute("data-tor-slide-target")) || document.querySelectorAll(/[^\/]+$/.exec(control.href)[0]);
      let slideTo = control.getAttribute("data-tor-slide-to");

      control.addEventListener("click", (e) => {
        e.preventDefault();
        for (const target of targets) {
          TORUS.Slider.slideTo(target, slideTo);
        }
      });
    }
  }

  /**
   * Init
   */

  static init(elements, options) {
    elements = getIterableElement(elements || ".tor-slider");
    initClass({name: "Slider", elements: elements, options: options});

    this.createExternalControls();

    REFRESH_ON_RESIZE.slider = true;
  }
}

// TORUS.Slider.init();
onLoad("Slider", "load", getIterableElement(".tor-slider"))

export default TORUS.Slider;