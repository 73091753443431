import TORUS from "./namespace";

import {
  OBSERVER,
  getIterableElement,
  initClass,
  callFunction,
} from "./util";

/**
 * ------------------------------------------------------------------------
 * Effects
 * (c) Torus Kit
 * ------------------------------------------------------------------------
 */

/**
 * ------------------------------------------------------------------------
 * TORUS.FxContainer Class init
 * ------------------------------------------------------------------------
 */

TORUS.FxContainer = class {
  constructor(element) {
    this.element = element;
  }

  /**
   * ------------------------------------------------------------------------
   * Set attributes
   * ------------------------------------------------------------------------
   */

  _addContainer() {
    // for (const container of elements) {
      this.element.TORUS = this.element.TORUS || {}
      this.element.TORUS.init = this.element.TORUS.init || {}
      this.element.TORUS.init.fn = "Fx"
      this.element.TORUS.init.elements = this.element.querySelectorAll("[data-tor-fx], [data-tor-fx-trigger~='inview']")

      for (const element of this.element.TORUS.init.elements) {
        element.hasTORUSContainer = true
      }

      OBSERVER("INTERSECTION", this.element);
    // }
  }

  /**
   * ------------------------------------------------------------------------
   * Init
   * ------------------------------------------------------------------------
   */

  static init(elements) {
    elements = getIterableElement(elements || "[data-tor-container]");
    initClass({name: "FxContainer", elements: elements});

    callFunction({elements: elements, object: "FxContainer", fn: "_addContainer" });
  }
}

export default TORUS.FxContainer;