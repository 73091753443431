import TORUS from "./namespace";

import {
  OBSERVER,
  SCROLLED,
  REFRESH_ON_RESIZE,
  getIterableElement,
  initClass,
  callFunction,
  optimizeAttribute,
  getProperties,
  getValueForCurrentResolution,
  getCustomTransforms,
  getAttributesWithValues,
  setBounds,
  expandCluster,
  isCurrentResolution,
  callAfterDocumentHeightChange,
} from "./util";

import {
  PREDEFINED,
  SCROLL_ELEMENTS,
  MOUSE_ELEMENTS,
  INVIEW_ELEMENTS,
  SENSOR_ELEMENTS,
  INTERSECTION_ELEMENTS,
  PARENT_ELEMENTS,
  REFRESH_SCROLL_ELEMENTS,
  MOUSE_ACTIONS,
  SCROLL_ACTIONS,
  ON_MOUSE,
  ON_SCROLL,
  ON_SENSOR,
} from "./fx.globals.js";

/**
 * ------------------------------------------------------------------------
 * Effects
 * (c) Torus Kit
 * ------------------------------------------------------------------------
 */

/**
 * ------------------------------------------------------------------------
 * TORUS.Fx Class init
 * ------------------------------------------------------------------------
 */

TORUS.Fx = class {
  constructor(element) {
    this.element = element;
    this.properties = this.properties || {};
    this.isFx = this.element.hasAttribute("data-tor-fx");

    if (this.element.hasAttribute("data-tor-fx")) {
      // this._setAttributes();
      // this._shortcuts();
      // this._setProperties();
      // this._createCSSVariables();
      // this._addToSet();
      // this._listenersActions();
    }

    if (this.element.hasAttribute("data-tor-fx-trigger")) {
      if (/inview/.test(this.element.getAttribute("data-tor-fx-trigger"))) {
        this.properties = this.properties || {};
        this.properties.inview = this.properties.inview || {};
        this.properties.inview.inviewElement = true;
        this.isParentInview = true;

        if(/inview:revert/.test(this.element.getAttribute("data-tor-fx-trigger"))) {
          this.properties.inview.inviewReset = true;
        }

        this._addToSet();
      }
    }

    // if (this.element.hasAttribute("data-tor-container")) {
    //   this.properties.inview = this.properties.inview || {};
    //   this.isContainer = true;
    //   this._addToSet();
    // }
  }

  /**
   * ------------------------------------------------------------------------
   * Set attributes
   * ------------------------------------------------------------------------
   */

  _setAttributes() {
    this.attributesAll = expandCluster(optimizeAttribute(this.element.getAttribute("data-tor-fx")));

    this.attributesWithValues = getAttributesWithValues(this.attributesAll);
    this.element.setAttribute("data-tor-fx", this.attributesAll);

    if(this.element.ownerSVGElement) {
      // this.setSVGProperties();
    }
    // console.log(this.attributesAll);
  }

  setSVGProperties() {
    this.svg = this.svg || {};
    this.svg.parent = this.element.ownerSVGElement;
    this.svg.parentTop = this.svg.parent.getBoundingClientRect().top + SCROLLED.top;
    // this.element.classList.add("no-transform");
    this.svg.top = this.element.getBoundingClientRect().top;
    // this.element.classList.remove("no-transform");
  }

  /**
   * Check if SVG element is visible in viewport
   * This has to be done to prevent unexpected behavior when calculating elements bounds
   */

  checkHidden() {
    /** */
    if(this.element.ownerSVGElement) {
      if(this.element.ownerSVGElement.clientHeight === 0 && this.element.ownerSVGElement.clientHeight === 0) {
        this.element.classList.add("hidden-svg-child");
      }
    }
    else {
      if(this.element.clientHeight === 0 && this.element.clientHeight === 0) {
        this.element.classList.add("hidden-element");
      }
      /** If the .hidden-on-init class has been applied */
      // setTimeout(() => {
      // 	this.element.classList.remove("hidden-on-init");
      // }, 10);
    }
  }

  checkParent() {
    if((/(scroll)|(mouse)|(sensor)+/g).test(this.attributesAll)) {
      PARENT_ELEMENTS.add(this.element.parentElement);
    }
  }

  additions() {
    // Reset SVG child origin
    if((/reset-svg-origin+/g).test(this.attributesAll)) {
      this.resetSVGOrigin();
    }
  }

  /**
   * ------------------------------------------------------------------------
   * Transform short-named data-tor-fx attributes to full name
   * Example: scroll:@parallax[10] -> scroll:@translateY[10px;0]__behavior[parallax]
   * ------------------------------------------------------------------------
   */

  _shortcuts() {
    /* Check if attributes contains "CSS property" defined by `@` */
    if(!/\:@parallax|\:@tilt+/g.test(this.attributesAll)) {
      return;
    }

    let attributesToRemove = [];

    for(const attribute of this.attributesWithValues.filter(item => { return (/(@parallax)|(@tilt)+/g).test(item) })) {

      let tiltOrigin;
      let CTValue;
      let CT = getCustomTransforms(attribute);

      if(/：/.test(attribute)) {
        CTValue = /\((.*?)\)/.exec(attribute)[1];
      }
      else {
        CTValue = CT.value;
      }

      tiltOrigin = "self-continuous";

      let properties = {
        "scroll:@parallax" : {action: "scroll", css: "transform.translateY", value: `${getNegativeValues(CTValue)}px;0px`, option: "behavior", optionValue: "parallax"},
        "mouseX:@parallax" : {action: "mouseX", css: "transform.translateX", value: `0px;${CTValue}px`, option: "origin", optionValue: "parallax"},
        "mouseY:@parallax" : {action: "mouseY", css: "transform.translateY", value: `0px;${CTValue}px`, option: "origin", optionValue: "parallax"},
        "mouse:@parallax" : {
          a: {action: "mouseX", css: "transform.translateX", value: `0px;${CTValue}px`, option: "origin", optionValue: "parallax"},
          b: {action: "mouseY", css: "transform.translateY", value: `0px;${CTValue}px`, option: "origin", optionValue: "parallax"},
        },
        "mouseX:@tilt" : {action: "mouseX", css: "transform.rotateY", value: `${getNegativeValues(CTValue)}deg;0deg`, option: "origin", optionValue: tiltOrigin},
        "mouseY:@tilt" : {action: "mouseY", css: "transform.rotateX", value: `${CTValue}deg;0deg`, option: "origin", optionValue: tiltOrigin},
        "mouse:@tilt" : {
          a: {action: "mouseX", css: "transform.rotateY", value: `${getNegativeValues(CTValue)}deg;0deg`, option: "origin", optionValue: tiltOrigin},
          b: {action: "mouseY", css: "transform.rotateX", value: `${CTValue}deg;0deg`, option: "origin", optionValue: tiltOrigin},
        },
      }

      for(let [key, value] of Object.entries(properties)) {
        let match = new RegExp(key);

        if(match.test(attribute)) {
          if(value.a) {
            this.add({attribute: `${value.a.action}:@${value.a.css}(${value.a.value})__${value.a.option}(${value.a.optionValue})`, isInternal: true});
            this.add({attribute: `${value.b.action}:@${value.b.css}(${value.b.value})__${value.b.option}(${value.b.optionValue})`, isInternal: true});
          }
          else {
            this.add({attribute: `${ value.action }:@${ value.css }(${ value.value })__${ value.option }(${ value.optionValue })`, isInternal: true});
          }
          attributesToRemove.push(attribute);
        }
      }
    }

    function getNegativeValues(value) {
      if(typeof value === "number") {
        return -value;
      }
      if(typeof value === "string") {
        return value.replace(/\d+/g, ($1) => { return `-${$1}` } )
      }
    }

    this._setAttributes();

    if(attributesToRemove.length) {
      attributesToRemove.map( attribute => { this.remove({attribute: attribute, isInternal: true}); });
    }

    // this._setProperties();
  }

  /**
   * ------------------------------------------------------------------------
   * Set properties object with options extracted from [data-tor-fx]
   * ------------------------------------------------------------------------
   */

  _setProperties() {

    /**
     * Create new properties objects
     */

    this.properties = this.properties || {};
    this.properties.inview = this.properties.inview || {};
    this.properties.static = this.properties.static || {};
    this.properties.customTransform = this.properties.customTransform || {};
    this.bounds = this.bounds || {};

    for(const attribute of this.attributesAll.split(" ")) {
      if(!/(:@parallax)|(:@tilt)+/g.test(attribute)) {
        /**
         * Check if attribute has "self" origin than add into REFRESH_SCROLL_ELEMENTS set
         * This set will be used when page has scrolled - dynamically refresh element properties
         */
        if( (/__origin\(self+/g).test(attribute) ) {
          REFRESH_SCROLL_ELEMENTS.add(this);
        }

        /**
         * IF: attribute has `@` "custom transform" identifier
         * insert {value} into "customTransform" object
         */
        if ( /scroll:|mouse:|mouseX:|mouseY:|sensor:|sensorX:|sensorY:+/g.test(attribute) ) {
          // Custom Transforms
          let CT = getCustomTransforms(attribute);

          // Current Values
          let CV;

          this.properties.customTransform[CT.actionType] = this.properties.customTransform[CT.actionType] || {};

          for(const [key, value] of Object.entries(CT)) {
            this.properties.customTransform[CT.actionType][CT.activeName] = this.properties.customTransform[CT.actionType][CT.activeName] || {};
            this.properties.customTransform[CT.actionType][CT.activeName][key] = value;
          }

          // Create Current Values object
          CV = this.properties.customTransform[CT.actionType][CT.activeName];
          CV.currentValues = CV.currentValues || {};

          // Set current "start" value
          CV.currentValues.start = getValueForCurrentResolution(CT.value).start;

          // Set current "end" value
          CV.currentValues.end = getValueForCurrentResolution(CT.value).end;

          // Set offset if <action> is <scroll>
          if(CT.actionType === "scroll") {
            CV.currentValues.offsetStart = getValueForCurrentResolution(CT.options.offset).start || null;
            CV.currentValues.offsetEnd = getValueForCurrentResolution(CT.options.offset).end;

            /** Special case, when transform begins after scrolled of certain amount of pixels */
            CV.currentValues.afterScrolledStart = getValueForCurrentResolution(CT.options.after).start || null;
            CV.currentValues.afterScrolledEnd = getValueForCurrentResolution(CT.options.after).end;
          }

          // Set global offset for inview element
          if(CT.actionType === "inview") {
            this.properties.customTransform.inview.inview_offset = CT.options.offset || 0;
          }
        }

        /**
         * ELSE IF: attribute has `inview` trigger
         * insert <value> into "inview" object
         */
        else if( (/inview:+/g).test(attribute) ) {
          let GP = getProperties(attribute);

          for(const [key, value] of Object.entries(GP)) {
            this.properties.inview[GP.activeName] = this.properties.inview[GP.activeName] || {};
            this.properties.inview[GP.activeName][key] = value;
          }

          /** Mark it as inviewElement */
          this.properties.inview.inviewElement = true;

          /** If reset inview */
          if(/inview:reset]+/g.test(this.attributesAll)) {
            this.properties.inview.inviewReset = true;
          }

          /** Set global offset for inview element */
          if(GP.actionType === "inview") {
            this.properties.inview.inview_offset = GP.options.offset || 0;
          }
        }

        /**
         * ELSE insert {value} into "static" object
         */
        else {
          let GP = getProperties(attribute);

          if (!GP) {
            console.error(`Unknown property: ${attribute}`);
            return;
          }

          for(const [key, value] of Object.entries(GP)) {
            this.properties.static[GP.activeName] = this.properties.static[GP.activeName] || {};
            this.properties.static[GP.activeName][key] = value;
          }

          if(GP.name === "hover-fix") {
            setTimeout(() => {
              this.element.classList.add("torus-enabled");
            }, 100);
          }
        }
      }
    }

    // for (const attribute of attributesOther) {
    //   let GP = getProperties(attribute);

    //   if (!GP) {
    //     console.error(`Unknown property: ${attribute}`);
    //     return;
    //   }

    //   for(const [key, value] of Object.entries(GP)) {
    //     this.properties.static[GP.activeName] = this.properties.static[GP.activeName] || {};
    //     this.properties.static[GP.activeName][key] = value;
    //   }

    //   if(GP.name === "hover-fix") {
    //     setTimeout(() => {
    //       this.element.classList.add("torus-enabled");
    //     }, 100);
    //   }
    // }

    /** Create hover hit area for some effects */
    if (/hover:(push|pull|rotate)/.test(this.attributesAll)) {
      if (!this.element.querySelector(".tor-hit-area")) {
        const hit = document.createElement("span");
        hit.classList.add("tor-hit-area");
        this.element.appendChild(hit);
      }
    }
  }

  /**
   * ------------------------------------------------------------------------
   * Create object that stores all generated CSS variables for the later assignment
   * ------------------------------------------------------------------------
   */

  _createCSSVariables() {
    this.cssVar = {};

    /* Loop through all properties (inview, static, customTransform) */
    for (const propertyType of Object.keys(this.properties)) {

      /* Continue only if there are any entries */
      if (Object.entries(this.properties[propertyType]).length) {

        /* Loop through all entries */
        for (const propertyValue of Object.values(this.properties[propertyType])) {

          /* If attribute has resolution defined with `::`. Example: hover:xl::fade.in */
          if (propertyValue.resolution) {
            /** Remove resolution from attribute definition */
            let tempAttribute = `${propertyValue.resolution.method ? propertyValue.resolution.method : ""}${propertyValue.resolution.name}`;
            let replacedAttribute = propertyValue.attribute.replace(tempAttribute, "");

            /** If the resolution equals current resolution, replace the original attribute with the one without the resolution */
            if(isCurrentResolution(propertyValue.resolution)) {
              this.element.setAttribute("data-tor-fx", this.attributesAll.replace(propertyValue.attribute, replacedAttribute));
            }
            /** Else revert back */
            else {
              this.element.setAttribute("data-tor-fx", this.attributesAll.replace(replacedAttribute, propertyValue.attribute));
            }
          }

          /* If attribute has value defined in `()`. Example: push.up(sm)  */
          if (propertyValue.value || propertyValue.value === 0) {
            let start;
            let PV = propertyValue;
            let end = getValueForCurrentResolution(PV.value).end;

            if (PV.value instanceof Object) {
              if (PV.value.start) {
                start = getValueForCurrentResolution(PV.value).start;
              }
            }

            /** Add CSS */
            if(propertyType !== "customTransform") {
              let cssVarValue;
              let action = PV.action ? `${PV.action}-` : ``;
              let name = PV.name;
              let options = PV.options;
              let direction = PV.direction ? `${PV.direction}` : ``;
              let modifier = PV.modifier ? PV.modifier : ``;
              let unit = PV.unit ? PV.unit : ``;

              cssVarValue = PREDEFINED.includes(`${end}${unit}`) ? `var(--tor-${name}-${end}${unit})` : `${end}${unit}`;

              if(action) {
                this.cssVar[`--tor-${action}${name.replace(/__.*/g, "")}${direction}`] = cssVarValue;
              }

              /** Has custom value property[{value}] */
              if(end && !action) {
                this.cssVar[`--tor-${name}${modifier}`] = cssVarValue;
              }

              if(options) {
                for (const [optionName, optionValue] of Object.entries(options)) {
                  let optionValueEnd = getValueForCurrentResolution(options[optionName]).end || optionValue;
                  let optionUnit = optionValue.unit || "";

                  optionValueEnd = /^--/.test(optionValueEnd) ? `var(${optionValueEnd})` : optionValueEnd;
                  this.cssVar[`--tor-${name}-${optionName}`] = `${optionValueEnd}${optionUnit}`;
                }
              }
            }

          }
        }
      }
    }
  }

  /**
   * ------------------------------------------------------------------------
   * Add element into corresponding set
   * ------------------------------------------------------------------------
   */

  _addToSet() {

    if ((/inview+/g).test(this.attributesAll) || this.isParentInview) {
      INVIEW_ELEMENTS.add(this);
    }
    if ((/scroll+/g).test(this.attributesAll)) {
      SCROLL_ELEMENTS.add(this);
    }
    if ((/mouse+/g).test(this.attributesAll)) {
      MOUSE_ELEMENTS.add(this);
    }
    if ((/sensor+/g).test(this.attributesAll)) {
      SENSOR_ELEMENTS.add(this);
    }
    if ((/(mouse)|(scroll)|(sensor)|(inview)+/g).test(this.attributesAll)) {
      INTERSECTION_ELEMENTS.add(this);
    }
    if (this.isContainer) {
      INTERSECTION_ELEMENTS.add(this);
    }
  }

  /**
   * ------------------------------------------------------------------------
   * Actions performed on event listeners
   * ------------------------------------------------------------------------
   */

  _listenersActions() {

    if (/scroll/.test(this.attributesAll)) {
      window.requestAnimationFrame(() => {
        this.visible = true;
        setTimeout(() => {
          if (this.visible) {
            this.element.classList.add("tr-none");
            // this.element.style.setProperty("transition", "none", "important");
            SCROLL_ACTIONS(this);

            /** Prevent flickering caused by applying new transform to element */
            setTimeout(() => {
              this.element.classList.remove("tr-none");
              // this.element.style.removeProperty("transition");
            }, 10);
          }
        }, 100);
      });
    }

    if (/mouse/.test(this.attributesAll)) {
      this.visible = true;
      setTimeout(() => {

        if (this.visible) {
          this.element.classList.add("tr-none");
          MOUSE_ACTIONS(this);

          /** Prevent flickering caused by applying new transform to element */
          setTimeout(() => {
            this.element.classList.remove("tr-none");
          }, 10);
        }
      }, 10);
    }

    if (this.isContainer) {
      setTimeout(() => {
        SCROLL_ACTIONS(this);
      }, 10);
    }
  }

  /**
   * ------------------------------------------------------------------------
   * Set element bounds using Util.setBounds() and add into this.bounds object
   * ------------------------------------------------------------------------
   */

  _setElementBounds() {
    setBounds(this);
  }

  /**
   * ------------------------------------------------------------------------
   * Reset SVG origin
   * ------------------------------------------------------------------------
   */

  resetSVGOrigin() {
    if(this.element.ownerSVGElement) {
      this.element.style.setProperty("--svg-x", `${this.element.getBBox().x}px`);
      this.element.style.setProperty("--svg-y", `${this.element.getBBox().y}px`);
      this.element.style.setProperty("--svg-w", `${this.element.getBBox().width}px`);
      this.element.style.setProperty("--svg-h", `${this.element.getBBox().height}px`);
    }
  }

  /**
   * ------------------------------------------------------------------------
   * Methods
   * ------------------------------------------------------------------------
   */

  /**
   * Add data-tor-fx property
   */

  add(param) {
    if(this.element.hasAttribute("data-tor-fx")) {
      this.element.setAttribute("data-tor-fx", [ ...new Set([...param.attribute.split(" "), ...this.element.getAttribute("data-tor-fx").split(" ")]) ].join(" "));
    }
    else {
      this.element.setAttribute("data-tor-fx", param.attribute);
    }

    if(!param.isInternal) {
      this._setAttributes();
    }

    if(!param.isExternal) {
      this._setProperties();
    }

    if(param.isExternal) {
      this._shortcuts();
    }

  }

  /**
   * Remove data-tor-fx property
   */

  remove(param) {
    let GP = getProperties(param.attribute);

    if(!param.attribute) {
      return;
    }

    this.attributesAll = this.attributesAll.split(" ").filter( itemToRemove => itemToRemove !== param.attribute ).join(" ");
    this.attributesWithValues = getAttributesWithValues(this.attributesAll);
    this.element.setAttribute("data-tor-fx", this.attributesAll);

    this._setProperties();

    if(this.element.getAttribute("data-tor-fx").length === 0) {
      this.element.removeAttribute("data-tor-fx");
    }

    if(!param.isInternal) {
      delete this.properties[GP.propertyType][GP.actionType][GP.activeName];
    }

    if( (/__origin\(self+/g).test(param.attribute) ) {
      REFRESH_SCROLL_ELEMENTS.delete(this);
    }

  }

  checkInview() {
    OBSERVER("INVIEW", this.element);
  }

  /**
   * Refresh
   */

  refresh() {
    this._setProperties();
    this._setElementBounds();
    this._createCSSVariables();
    this._addCSSVariables();
    this._addToSet();
    this._listenersActions();
  }


  /**
   * ------------------------------------------------------------------------
   * Add generated CSS variables to each element in one loop to prevent a reflow
   * ------------------------------------------------------------------------
   */

  _addCSSVariables() {
    if (this.cssVar && !this.async) {
      for (const [key, value] of Object.entries(this.cssVar)) {
        this.element.style.setProperty(key, value);
      }
    }
  }

  // _setAttributes() {
  //   if (this.isFx) {
  //     this._setAttributes();
  //     // this._shortcuts();
  //     // this._setProperties();
  //     // this._createCSSVariables();
  //     // this._addToSet();
  //     // this._listenersActions();
  //   }
  // }

  /**
   * ------------------------------------------------------------------------
   * Public methods
   * ------------------------------------------------------------------------
   */

  static add(elements, property) {
    if(arguments.length != 2) throw Error("Wrong numbers of parameters! Required: 2");

    initClass({name: "Fx", elements: getIterableElement(elements)});
    callFunction({elements: getIterableElement(elements), argument: {attribute: property, isExternal: true}, object: "Fx", fn: "add"});
  }

  static remove(elements, property) {
    if(arguments.length != 2) throw Error("Wrong numbers of parameters! Required: 2");
    callFunction({elements: getIterableElement(elements), argument: {attribute: optimizeAttribute(property)}, object: "Fx", fn: "remove"});
  }

  static refresh(elements) {
    callFunction({elements: getIterableElement(elements || "[data-tor-fx]"), object: "Fx", fn: "refresh"});
    // this.addEventListeners(elements);
  }

  static addEventListeners() {
    document.addEventListener("mousemove", ON_MOUSE, true);
    document.addEventListener("scroll", ON_SCROLL, true);
    window.addEventListener("deviceorientation", ON_SENSOR, true);
  }

  //---------------

  static checkInview() {
    for (const _this of INVIEW_ELEMENTS) {
      OBSERVER("INVIEW", _this.element);
    }
  }

  // static addCSSVariables(elements) {
  //   callFunction({elements: getIterableElement(elements || "[data-tor-fx]"), object: "Fx", fn: "_addCSSVariables"});
  // }

  // static _setElementBounds(elements) {
  //   callFunction({elements: getIterableElement(elements || "[data-tor-fx]"), object: "Fx", fn: "_setElementBounds"});
  // }

  /**
   * ------------------------------------------------------------------------
   * Initialize
   * ------------------------------------------------------------------------
   */

  static init(elements) {
    elements = getIterableElement(elements || "[data-tor-fx], [data-tor-fx-trigger~='inview']");

    initClass({name: "Fx", elements: elements});

    /** Batch function call to reduce reflow */
    for (const fn of ["_setAttributes", "_shortcuts", "_setProperties", "_createCSSVariables", "_addToSet", "_listenersActions"]) {
      callFunction({elements: elements, object: "Fx", fn: fn});
    }

    callAfterDocumentHeightChange(elements)

    // setTimeout(() => {
    //   callFunction({elements: elements, object: "Fx", fn: "_addCSSVariables"});
    //   callFunction({elements: elements, object: "Fx", fn: "checkInview"});
    //   callFunction({elements: elements, object: "Fx", fn: "_setElementBounds"});
    // }, 100);

    /** Add scroll and mouse event listeners */
    this.addEventListeners(elements);


    /** Hover fix */
    for(const trigger of document.querySelectorAll("[data-tor-fx-trigger~='hover-fix']")) {
      setTimeout(() => {
        trigger.classList.add("torus-enabled");
      }, 100);
    }

    /** Enable refresh on resize */
    REFRESH_ON_RESIZE.fx = true;

  }
}

export default TORUS.Fx;
