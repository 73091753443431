/**
 * ------------------------------------------------------------------------
 * Custom position
 * (c) Torus Kit
 * ------------------------------------------------------------------------
 */

import TORUS from "./namespace";

import {
  getIterableElement,
  initClass,
  callFunction,
  optimizeAttribute,
  getProperties,
  getValueForCurrentResolution,
  REFRESH_ON_RESIZE,
  onLoad,
} from "./util";

TORUS.Position = class {
  constructor(element) {
    this.element = element;
  }

  setPositionAndPush(attributes, type) {
    for(const attribute of attributes) {

      /**
       * Grab all values that has `()`
       */
      if((/(\()*(\))/g).test(attribute)) {
        let GP = getProperties(attribute);
        let value = getValueForCurrentResolution(GP.value).end;

        switch (type) {
          case "position":
            this.element.style.setProperty(`--tor-position-${GP.name}`, `${value}${GP.unit ? GP.unit : ""}`);
            break;

          case "push":
            let name = GP.name.replaceAll({ "up": "y", "down": "y", "left": "x", "right": "x" })
            this.element.style.setProperty(`--tor-push-${name}`, `${value}${GP.unit ? GP.unit : "%"}`);
            break;
        }


      }
    }
  }

  setAttributes() {
    if(this.element.getAttribute("data-tor-position")) {
      this.positions = optimizeAttribute(this.element.getAttribute("data-tor-position")).split(" ");
      // this.positions && this.setPositionAndPush(this.positions, "position");
    }

    if(this.element.getAttribute("data-tor-push")) {
      this.pushes = optimizeAttribute(this.element.getAttribute("data-tor-push")).split(" ");
      // this.pushes && this.setPositionAndPush(this.pushes, "push");
    }
  }

  refresh() {
    this.positions && this.setPositionAndPush(this.positions, "position");
    this.pushes && this.setPositionAndPush(this.pushes, "push");
  }

  /**
   * ------------------------------------------------------------------------
   * Public methods
   * ------------------------------------------------------------------------
   */

  static refresh(elements) {
    callFunction({elements: getIterableElement(elements || "[data-tor-position*='('], [data-tor-push]"), object: "Position", fn: "refresh"});
  }

  static setAttributes(elements) {
    callFunction({elements: getIterableElement(elements || "[data-tor-position*='('], [data-tor-push]"), object: "Position", fn: "setAttributes"});
  }

  /**
   * ------------------------------------------------------------------------
   * Initialize
   * ------------------------------------------------------------------------
   */

  static init(elements) {
    elements = getIterableElement(elements || "[data-tor-position*='('], [data-tor-push]");
    initClass({name: "Position", elements: elements});

    /** Batch */
    this.setAttributes(elements);
    this.refresh(elements);

    REFRESH_ON_RESIZE.position = true;
  }
}

// TORUS.Position.init();
onLoad("Position", "DOMContentLoaded");

export default TORUS.Position;
