/**
 * ------------------------------------------------------------------------
 * Util
 * (c) Torus Kit
 * ------------------------------------------------------------------------
 */

import TORUS from "./namespace";

/**
 * Webkit polyfill
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/entries#Polyfill
 */

if (!Object.entries) {
  NodeList.prototype[Symbol.iterator] = Array.prototype[Symbol.iterator];
  Object.entries = function( obj ){
    var ownProps = Object.keys( obj ),
        i = ownProps.length,
        resArray = new Array(i);
    while (i--)
      resArray[i] = [ownProps[i], obj[ownProps[i]]];

    return resArray;
  };
}

/**
 * ------------------------------------------------------------------------
 * Get computed style value
 * ------------------------------------------------------------------------
 */

const getStyleValue = (element, value) => {
  return window.getComputedStyle(element, null).getPropertyValue(value).trim();
};

/**
 * ------------------------------------------------------------------------
 * Get the value of CSS "--resolution" variable
 * ------------------------------------------------------------------------
 */

const getCurrentResolution = () => {
  return getStyleValue(document.body, "--resolution");
};

/**
 * ------------------------------------------------------------------------
 * Check if given <resolutionNumber> equals or lower/higher than the current resolution
 * ------------------------------------------------------------------------
 */

const isCurrentResolution = (resolution) => {
  switch (resolution.method) {
    case "<":
      if(Number(WINDOW.resolution) < resolution.number) {
        return true;
      }
      break;

    case "=":
      if(Number(WINDOW.resolution) === resolution.number) {
        return true;
      }
      break;

    default:
      if(Number(WINDOW.resolution) >= resolution.number) {
        return true;
      }
      break;
  }
}

/**
 * ------------------------------------------------------------------------
 * Window size object
 * ------------------------------------------------------------------------
 */

const WINDOW = {
  height: window.innerHeight || document.documentElement.clientHeight,
  width: window.innerWidth || document.documentElement.clientWidth,
  resolution: getCurrentResolution(),
  resizing: false,
}

/**
 * ------------------------------------------------------------------------
 * Scrolled object
 * ------------------------------------------------------------------------
 */

const SCROLLED = {
  top: window.scrollY,
  left: window.scrollX,
}

/**
 * ------------------------------------------------------------------------
 * Mouse position object
 * ------------------------------------------------------------------------
 */

const MOUSE = {
  x: 0,
  y: 0,
}

/**
 * ------------------------------------------------------------------------
 * Sensor position object
 * ------------------------------------------------------------------------
 */

const SENSOR = {
  x: 0,
  y: 0,
}

/**
 * ------------------------------------------------------------------------
 * Refresh desired elements on resize
 * ------------------------------------------------------------------------
 */

const REFRESH_ON_RESIZE = {
  fx: false,
  position: false,
  push: false,
  slider: false,
  alert: false,
}

// On resize handler

function ON_RESIZE() {
  let tick = 0;

  if (!WINDOW.resizing) {
    requestAnimationFrame(raf);
    WINDOW.resizing = true;
  }

  function raf() {
    if (tick >= 50) {
      WINDOW.resizing = false;
      cancelAnimationFrame(raf);

      WINDOW.height = window.innerHeight || document.documentElement.clientHeight;
      WINDOW.width  = window.innerWidth || document.documentElement.clientWidth;
      WINDOW.resolution = getCurrentResolution();


      if(REFRESH_ON_RESIZE.fx) {
      	TORUS.Fx.refresh();
      }

      if(REFRESH_ON_RESIZE.loop) {
      	TORUS.Loop.refresh();
      }

      if(REFRESH_ON_RESIZE.position) {
      	TORUS.Position.refresh();
      }

      if(REFRESH_ON_RESIZE.push) {
      	TORUS.Push.refresh();
      }

      if(REFRESH_ON_RESIZE.slider) {
      	TORUS.Slider.refresh();
      }
    }
    else {
      tick = tick + 1;
      requestAnimationFrame(raf);
    }
  }
}

window.addEventListener("resize", ON_RESIZE);

/**
 * ------------------------------------------------------------------------
 * Transform string to camel case
 * ------------------------------------------------------------------------
 */

String.prototype.toCamelCase = function () {
  return this.replace(/[-_]+/g, " ").replace(/ (.)/g, function ($1) { return $1.toUpperCase(); }).replace(/ /g, "");
};

/**
 * ------------------------------------------------------------------------
 * Extract unit, value, resolution and counting number
 * isString will threat the `property` value as a string and disable extracting value and unit
 * ------------------------------------------------------------------------
 */

const getData = (property, isString) => {
  let value = null;
  let unit = null;
  let resolution = null;
  let counting = null;
  let random = null;
  let valueObject = {};
  // let customValue = null;

  /**
   * Extract <resolution>
   */

  if (/：/.test(property)) {
    let match = /(sm：)|(md：)|(lg：)|(xl：)|(xxl：)+/g.exec(property);
    resolution = match[0];
    property = property.replace(resolution, "");
  }

  /**
   * Extract <unit>. If property = 0.5rem, then {unit} = rem
   * If value does not starts with `@` (custom value)
   */

  if (!/^@/.test(property) && /^(-|\+|\/\+|\/-|\/~|)\d/g.test(property)) {
    let unitMatch = /(?!\d)(px|deg|%|cm|mm|in|pt|pc|em|ex|ch|rem|vw|vh|vmin|vmax|ms|s)+/g.exec(property.replace(/\/+/g, ""));
    if (unitMatch) {
      unit = unitMatch[1];
      property = property.replace(unit, "");
    }
  }

  /**
   * Extract <value>
   * If property = 0.5rem, then {value} = 0.5
   */

  if (property) {
    if (isString) {
      value = property;
    }
    else {
      /** If has any digit, but not start with `# `(hex color) and `...` (has multiple values) */
      if (!/#/.test(property) && !/\.\.\.\b/.test(property) && /\d+/g.test(property)) {
        value = parseValue(property);
      }
      /** Else: it's a string */
      else {
        value = property;
      }
    }
  }


  /**
   * Extract <counting>
   */

  // if (/\((.*?)\)+/g.exec(property)) {
  if (/\/(.*?)\/+/g.exec(property)) {

    if (!/-|\+|~/.test(/\/(.*?)\/+/g.exec(property)[1])) {
      console.error(`No counting sign found in parenthesis. Add "+", "-" or "~" to your counting value`);
      return;
    }

    let matches;

    /**
     * Extract one or multiple values between slashes: `/<values>/`.
     * @example: `/rgb(+1, +2, 10+3)/` will return `match = ["+1", "+2", "10+3"]`
     * @url-example: regexr.com/5kqhu
     */
    matches = property.match(/(?!(~|-|\+)(([0-9]*[.])?[0-9]+)(-|\+))(~|-|\+)(@|(([0-9]*[.])?[0-9]+))+|(([0-9]*[.])?[0-9]+)(\+|-|~)(@|)(([0-9]*[.])?[0-9]+)/g);

    /** Only one match: delay(/+50ms/) or delay(/10+50ms/) */
    if (matches.length === 1) {
      let GC = getCounts(matches[0]);
      value = GC.value;
      counting = GC.counting;
      random = GC.random;
    }
    /** TODO: Multiple matches: rgb(/+1, +5, +5/) */
    else {
      for (let [i, match] of Object.entries(matches)) {
        let GC = getCounts(match);
        valueObject[i] = {};
        valueObject[i].value = GC.value;
        valueObject[i].counting = GC.counting;
        valueObject[i].random = GC.random;
      }
      value = valueObject;
    }

    function getCounts(matches) {
      let match;
      let first;
      let last;
      let count;
      let val;
      let rand;

      match = matches.match(/(~|~.|\+|\+.|-|-.)*(([0-9]*[.])?[0-9]+)/g);

      first = match[0];
      last = match[1];
      rand = /~/g.test(last || first) && true;

      /** If there is also a <start-value>: /10+50ms/ the `10` is a start-value*/
      if (last) {
        last = last.replace(/@|~/g, "");
        count = /\.\d/g.test(last) ? parseFloat(last) : parseInt(last);
        val = Number(first);
      }
      /** Only one counting value: /+50ms/ */
      else {
        first = first.replace(/@|~/g, "");
        count = /\.\d/g.test(first) ? parseFloat(first) : parseInt(first);
        val = 0;
      }

      return {
        value: val,
        counting: count,
        random: rand,
      }
    }
  }

  function parseValue(value) {
    /** Check if number is float */
    if (/(^(\d.*?)|^()|^(-\d.*?))\.(\d)+/g.test(value)) {
      value = parseFloat(/((-|\+|)[0-9]*[.])?[0-9]+/g.exec(value)[0]);
    }
    /** Number is integer */
    else if (/^[-+]?\d+$/.test(value)) {
      value = parseInt(/[+-]?(\d)+/g.exec(value)[0]);
    }
    /** Number is string (or contains number with non-number character) */
    else {
      value = value;
    }

    return value;
  }

  /** Remove temporary space character with regular one */
  if (typeof value === 'string' || value instanceof String) {
    value = value.replace(/░/g, " ");
  }

  return {
    value,
    unit,
    resolution,
    counting,
    random,
  };
};

/**
 * ------------------------------------------------------------------------
 * Optimize given attributes
 * ------------------------------------------------------------------------
 */

const optimizeAttribute = (attribute) => {
  if(!attribute) {
    return "";
  }

  let optimized = removeSpaces(
    attribute
      .replace(/\s\s+/g, " ")
      .replace(/ $/g, "")
      .replace(/ xs::| sm::| md::| lg::| xl::| xxl::/g, match => match.replace(" ", "⠀"))
      .replace(/::+/g, "："),
      "\\[ | \\]|{ | }| { | : |: | :| ; |; | ;| ,|, | , |\\( | \\)|\\(\\( | \\(\\(| \\(\\( | \\)\\)| =>|=> | => | \\+| \\+ |\\+ | ~| ~ |~ | \\(")
      .replace(/\((.*?)\)+/g, match => match.replace(/ +/g, "░"))
      .replace(/\\/g, "")
      .trim();

  return optimized;
};

/**
 * ------------------------------------------------------------------------
 * Remove unnecessary spaces and unify them, remove tabs, etc
 * ------------------------------------------------------------------------
 */

const removeSpaces = (string, replacement) => {
  let oldString = string;
  let newString, re, replacedPattern;

  for( const pattern of replacement.split("|") ) {
    replacedPattern = pattern.replace(/ /g, "");
    re = new RegExp(pattern, "g");
    newString = oldString.replace(re, replacedPattern);
    oldString = newString.replace(/\\+/g, "");
  }

  return newString;
}

/**
 * ------------------------------------------------------------------------
 * Wrap element
 * ------------------------------------------------------------------------
 */

const wrapElement = (elements, wrapper, elementClass) => {
  if(elements instanceof Node) {
    elements = [elements];
  }

  if (wrapper instanceof Object) {
    let newElement = document.createElement("div");
    for (const element of elements) {
      newElement.appendChild(element);
    }

    wrapper.appendChild(newElement);
    elementClass && newElement.classList.add(elementClass);
  }
  else {
    for(let element of elements) {
      let newElement;
      let parentElement;
      let nextElement;

      nextElement = element.nextElementSibling;

      if(wrapper === "svg") {
        newElement = document.createElementNS("http://www.w3.org/2000/svg", "svg");
        newElement.setAttribute("xmlns", "http://www.w3.org/2000/svg");
      }
      else {
        newElement = document.createElement(wrapper);
      }

      parentElement = element.parentElement;
      newElement.appendChild(element);
      elementClass && newElement.classList.add(elementClass);
      parentElement.insertBefore(newElement, nextElement);
    }
  }
}

/**
 * ------------------------------------------------------------------------
 * Get properties
 * ------------------------------------------------------------------------
 */

const getProperties = (property, isString) => {
  let options = null;
  let resolution = null;
  let action = null;
  let name = null;
  let modifier = null;
  let value = null;
  let direction = null;
  let activeName = null;
  let propertyType = null;
  let actionType = null;
  let unit = null;
  let priority = null;
  let cssPropertyType = null;
  let multiValues = {};

  let attribute = property;

  multiValues.enabled = null;
  multiValues.cssFunction = null;

/**
 * ----------------------------------
 * Check <priority>
 * ----------------------------------
 */

  if (/^(\!)/.test(property)) {
    priority = true;
    property = property.replace("!", "");
  }

  /**
   * ----------------------------------
   * Extract <action/trigger> from string using `:` then removes it
   * ----------------------------------
   */

  if (/(.+\:)(?!\:)+/g.test(property)) {
    action = /[^\(\:]*/.exec(property)[0];
    property = /\:(.*)/.exec(property)[1];
  }

  /**
   * ----------------------------------
   * Extract <resolution> defined by `::`
   * ----------------------------------
   */

  if (/^(<|=|)(xs|sm|md|lg|xl|xxl)：/.test(property)) {
    resolution = {};
    resolution.method = null;

    /** Resolution method - how to calculate a resoltuion
     * `<` - up to resolution
     * `=` - equals resolution
     */
    if(/^(<|=)/.test(property)) {
      resolution.method = /^(<|=)/.exec(property)[0];
      property = property.replace(resolution.method, "");
    }

    resolution.name = /^(xs|sm|md|lg|xl|xxl)：/.exec(property)[0];

    switch (resolution.name) {
      case "xxl：":
        resolution.number = 5;
        break;

      case "xl：":
        resolution.number = 4;
        break;

      case "lg：":
        resolution.number = 3;
        break;

      case "md：":
        resolution.number = 2;
        break;

      case "sm：":
        resolution.number = 1;
        break;
    }

    property = property.replace(resolution.name, "");
  }

  /**
   * ----------------------------------
   * Extract <name>
   * ----------------------------------
   */

  if ((/(\b\()+/g).test(property)) {
    name = property.substring(0, property.indexOf((/(\b\()+/g).exec(property)[0])).replace(/^ +/g, "");
  }
  else {
    name = property;
  }

  /**
   * ----------------------------------
   * Extract <cssPropertyType>
   * ----------------------------------
   */

  if(/@transform\.|@filter\./.exec(name)) {
    cssPropertyType = /@(.*)\./.exec(name)[1];
    name = name.replace(cssPropertyType, "").replace(".", "").replace("@", "");
  }
  else {
    name = name.replace("@", "");
    cssPropertyType = name;
  }

  /**
   * ----------------------------------
   * Extract <modifier>
   * ----------------------------------
   */

  if (/\.+/g.test(name)) {
    let exec = /\.(.*?)\(|\.(.*?)$/g.exec(name);
    modifier = exec[2];
    name = name.replace(exec[0], "");

    if (/X$|Y$/.test(name)) {
      let exec = /X$|Y$/.exec(name)[0];
      name = name.replace(exec, "");
      direction = exec;
    }

    if (/left|right/.test(modifier)) {
      if (/push|pull/.test(name)) {
        direction = "X";
      }
      if (/shadow-offset/.test(name)) {
        direction = `-${modifier}`;
      }
    }
    if (/up|down/.test(modifier)) {
      if (/push|pull/.test(name)) {
        direction = "Y";
      }
      if (/shadow-offset/.test(name)) {
        direction = `-${modifier}`;
      }
    }

  }

  /**
   * ----------------------------------
   * Extract <activeName>
   * ----------------------------------
   */

  if(action) {
    /** Active name
     * input: h:push[up]
     * output: h_push_y
     */
    activeName = `${action}:${name}${direction ? ("-" + direction) : "" }`.replace(/(\:)+/g, "-");
  }
  else {
    activeName = name;
  }

  /**
   * ----------------------------------
   * Extract <options>
   * ----------------------------------
   */

  options = options || {};

  /**
   * Extract <options> defined by `__(<options>)`
   */

  if (/__/.test(property)) {
    let match = property.match(/__(.*?)\)+/g);

    if(match) {
      /** Loop through every option separated by "]__" in data-fx */

      // for(const [i, option] of Object.entries(property.replace(/\]__/g, "]]__").split("]__"))) {
      for(let option of match) {

        /** Remove "__option" from property string */
        property = property.replace(option, "");

        /** Remove "__" from option string */
        option = option.replace("__", "");

        /**
         * Set "value" to option "name"
         */

        let GP = getProperties(option);
        let optionName = GP.name;
        // let GPValue = GP.value;

        options[optionName] = {};

        /**
         * IF: option has "start" and "end" values
         */
        if(GP.value.start && GP.value.end) {
          /**
           * IF: Has unit
           */
          if(GP.unit) {
            options[optionName].unit = GP.unit;
          }
          /**
           * START
           *
           * IF: "start" is a number
           */
          if(Number(GP.value.start) || GP.value.start === "0" || GP.value.start === "-0") {
            options[optionName].start = Number(GP.value.start);
          }
          /**
           * ELSE: "start" is a string (has resolution defined)
           */
          else {
            options[optionName].start = GP.value.start;
          }

          /**
           * END
           *
           * "end" is a number
           */
          if(Number(GP.value.end) || GP.value.end === "0" || GP.value.end === "-0") {
            options[optionName].end = Number(GP.value.end);
          }
          /**
           * "end" is a string
           */
          else {
            options[optionName].end = GP.value.end;
          }
        }
        /**
         * ELSE: option has only one (end) value
         */
        else {
          /**
           * IF: Is a number
           */
          if(Number(GP.value)) {
            if(GP.unit) {
              options[optionName].end = Number(GP.value);
              if(GP.unit) {
                options[optionName].unit = GP.unit;
              }
            }
            else {
              options[optionName] = Number(GP.value);
            }
            // console.log(options);

          }
          /**
           * ELSE: Is a string (has resolutions defined, for example)
           */
          else {
            options[optionName] = GP.value;

            if(GP.unit) {
              options[optionName].unit = GP.unit;
            }

            /** If option is <target>, select the DOM node */
            if (optionName === "target") {
              options[optionName] = document.querySelectorAll(GP.value);
            }
          }
        }
      }
    }
  }

  /**
   * ----------------------------------
   * Extract <value>
   * ----------------------------------
   */

  if (/\((.*?)\)+/g.exec(property)) {
    let valueObject = {};
    let originalValue;

    if (/\((.*?)\)+$/g.exec(property)) {
      originalValue = /\((.*?)\)+$/g.exec(property)[0].replace(/\)$|^\(/g, "");
    }
    else {
      console.error(`Cannot execute the property: ${property}`);
      return;
    }

    unit = getData(originalValue, isString).unit;

    /** If: Has "start" and "end" parameter separated by semicolon ";" */
    if (/;/.test(originalValue)) {
      valueObject.start = valueObject.start || {};
      valueObject.end = valueObject.end || {};
      valueObject.start = getResolutions({ property: originalValue.split(";")[0], object: valueObject.start, name: "start", isString: isString });
      valueObject.end = getResolutions({ property: originalValue.split(";")[1], object: valueObject.end, name: "end", isString: isString });

      if (/\.\.\.\b/g.test(property)) {
        valueObject.start = getMultiValues(valueObject.start);
        valueObject.end = getMultiValues(valueObject.end);
      }
    }
    /** Else: Only one parameter */
    else {
      valueObject = getResolutions({ property: originalValue, object: valueObject, name: "end", isString: isString });
      // unitObject = getResolutions({ property: originalValue, object: unitObject, name: "unit", specific: "unit"});

      if (/\.\.\.\b/g.test(property)) {
        valueObject = getMultiValues(valueObject);
      }
    }

    value = valueObject;
  }
  else {
    // value = property;
    value = false;
  }

  /**
   * ----------------------------------
   * Extract {multi-values}
   * If property has multi values defined by [...value-start1,...value-startN ; ...value-end1,...value-endN]
   * ----------------------------------
   */

  function getMultiValues(value) {
    let items;
    /** New array for values */
    let array = [];

    multiValues.enabled = true;

    /** Split values */
    items = /\.\.\.(.*)\D/g.exec(value)[1].replace(/\.\.\.\b/g, "").split(",");

    /** If there is a CSS function such as `rgba()` */
    if (/^(.*)\(/.exec(value)) {
      multiValues.cssFunction = /^(.*)\(/.exec(value)[1];
    }
    else {
      multiValues.cssFunction = null;
    }

    /** Loop through values array */
    for(let item of Object.values(items)) {
      !isNaN(item) ? array.push(Number(item)) : array.push(item);
    }

    return array;
  }

  /**
   * ----------------------------------
   * Default settings for some effects
   * ----------------------------------
   */

  if(options.behavior === "parallax") {
    options.is_parallax = true;
  }

  if((/scroll+/g).test(action)) {
    options.behavior = options.behavior || "regular";

    if(typeof options.offset ==="undefined")  {
      options.offset = "middle";
    }
    else {
      options.offset =  options.offset;
    }

    options.is_scroll = true;
    options.event_type = "scroll";
    actionType = "scroll";
  }
  if((/mouse+/g).test(action)) {
    options.origin = options.origin || "middle";
    options.event_type = "mouse";
    actionType = "mouse";
  };
  if((/sensor+/g).test(action)) {
    options.event_type = "sensor";
    actionType = "sensor";
  };

  /**
   * Property type
   */

  if((/_ct_+/g).test(activeName)) {
    propertyType = "customTransform";
  }
  else {
    if((/inview_+/g).test(activeName)) {
      propertyType = "inview";
    }
    else if((/(hover)|(active)+/g).test(activeName)) {
      propertyType = "static";
    }
    else {
      propertyType = null;
    }
  }

  /**
   * ----------------------------------
   * Returns
   * ----------------------------------
   */

  return {
    action,
    actionType,
    activeName,
    attribute,
    cssPropertyType,
    name,
    direction,
    modifier,
    multiValues,
    options,
    priority,
    propertyType,
    resolution,
    unit,
    value,
  }
};

/**
 * ------------------------------------------------------------------------
 * Extract Custom transforms from given property
 * ------------------------------------------------------------------------
 */

const getCustomTransforms = (property) => {
  let action;
  let start = null;
  let end	= null;
  let unit = null;
  let direction = null;
  let cssVariable;
  let cssPropertyType;
  let activeName;
  let options;
  let GP = getProperties(property);
  let multiValues = GP.multiValues;
  let propertyType;
  let actionType;
  let value;

  /**
   * Set {action}
   */

  action = GP.action;

  value = GP.value;

  /**
   * Set {unit}
   */

  if(!multiValues.enabled) {
    if(GP.unit) {
      unit = GP.unit;
    }
    else {
      unit = ""
    }
  }

  /**
   * Set CSS variable. Example: {scroll}-{ct-translateX}
   */

  cssVariable = `--${action.replace(/(_x)|(_y)+/g, "")}-${GP.name}`;

  /**
   * Set {name}
   */

  name = GP.name;

  /**
   * Set <cssPropertyType> (if it's `transform, opacity, etc`)
   */

  cssPropertyType = GP.cssPropertyType;

  /**
   * Set {activeName}
   */

  // activeName = `${action}_ct_${name.replace(/-+/g, "_")}`;
  activeName = `${action}_ct_${name}`;

  /**
   * Options
   */

  options = GP.options || {};

  propertyType = GP.propertyType;

  actionType = GP.actionType;

  if( action.split("_").length > 1 ) {
    direction = action.split("_")[1];
  }

  return {
    action,
    actionType,
    activeName,
    direction,
    value,
    unit,
    cssVariable,
    cssPropertyType,
    name,
    multiValues,
    options,
    propertyType
  }
};

/**
 * ------------------------------------------------------------------------
 * Get value for current screen resolution
 * ------------------------------------------------------------------------
 */

const getValueForCurrentResolution = (optionObject) => {
  let start = null;
  let end = null;
  let value = null;
  let CURRENT_RESOLUTION = WINDOW.resolution;

  if(typeof optionObject === "object") {
    // START
    //
    // Check if "start" is object
    if(typeof optionObject.start === "object" && !Array.isArray(optionObject.start) ) {
      start = getCurrentValue(optionObject.start, "start");
    }
    else {
      start = getCurrentValue(optionObject, "start");
    }

    // END
    //
    // Check if "end" is object
    if(typeof optionObject.end === "object" && !Array.isArray(optionObject.end) ) {
      end = getCurrentValue(optionObject.end, "end");
    }
    else {
      end = getCurrentValue(optionObject, "end");
    }

    // VALUE
    //
    // Check if "value" is object
    if(typeof optionObject.value === "object" && !Array.isArray(optionObject.value) ) {
      value = getCurrentValue(optionObject.value, "value");
    }
    else {
      value = getCurrentValue(optionObject, "value");
    }
  }

  if(typeof optionObject === "number") {
    end = optionObject;
  }
  if(typeof optionObject === "string") {
    end = optionObject;
  }
  if(typeof optionObject === "boolean") {
    end = optionObject;
  }

  function getCurrentValue(optionObject, state) {
    let value;

    if(optionObject[`${state}_xxl`] && CURRENT_RESOLUTION >= 5) {
      value = optionObject[`${state}_xxl`];
    }
    else if(optionObject[`${state}_xl`] && CURRENT_RESOLUTION >= 4) {
      value = optionObject[`${state}_xl`];
    }
    else if(optionObject[`${state}_lg`] && CURRENT_RESOLUTION >= 3) {
      value = optionObject[`${state}_lg`];
    }
    else if(optionObject[`${state}_md`] && CURRENT_RESOLUTION >= 2) {
      value = optionObject[`${state}_md`];
    }
    else if(optionObject[`${state}_sm`] && CURRENT_RESOLUTION >= 1) {
      value = optionObject[`${state}_sm`];
    }
    else {
      value = optionObject[state];
    }

    return value;
  }

  return {
    start: start,
    end: end,
    value: value,
  }
};

/**
 * ------------------------------------------------------------------------
 * Extract resolutions from responsive values defined by "::"
 * ------------------------------------------------------------------------
 */

const getResolutions = (params) => {
  let property = params.property;
  let object = params.object;
  let _name = params.name;
  let isString = params.isString;

  /** Has resolutions */
  if(/：/.test(property)) {
    /** Split values by resolutions */
    for(let item of property.split("⠀")) {
      /** Resolution from MD up */
      if(/：/.test(item)){
        let resolution = item.split("：")[0];
        let item_value = item.split("：")[1];
        object[`${_name}_${resolution}`] = getData(item_value, isString).value;
        object[`${_name}`] = object[`${_name}`] || 0;
      }
      /** If no resolution is defined */
      else {
        object[`${_name}`] = getData(item, isString).value;
      }
    }
  }
  /** Has no resolutions */
  else {
    if(property === "true" || property === "false") {
      object = JSON.parse(property);
    }
    else {
      object = getData(property, isString).value;
    }
  }

  return object;
};

/**
 * ------------------------------------------------------------------------
 * Create iterable elements from given parameter
 * ------------------------------------------------------------------------
 */

const getIterableElement = (elements) => {
  if (typeof elements === "string") {
    elements = [...document.querySelectorAll(elements)];
  }
  if (elements instanceof Node) {
    elements = [elements];
  }
  if (elements instanceof NodeList) {
    elements = [].slice.call(elements);
  }

  if(!elements.length) {
    return;
  }

  if(elements.length) {
    return elements;
  }
  else {
    return false;
  }

};

/**
 * ------------------------------------------------------------------------
 * Intersection observer
 * ------------------------------------------------------------------------
 */

let INVIEW_OBSERVER;
let INTERSECTION_OBSERVER;
let PARENT_OBSERVER;
let IO_SUPPORTED = ("IntersectionObserver" in window) ? true : false;

const OBSERVER = (observerType, target) => {
  switch (observerType) {
    case "INTERSECTION":
      IO_SUPPORTED ? INTERSECTION_OBSERVER.observe(target) : FALLBACK_INTERSECTION(target);
      // IO_SVG(object);
      break;

    case "INVIEW":
      IO_SUPPORTED ? INVIEW_OBSERVER.observe(target) : FALLBACK_INVIEW(target);
      // IV_SVG(object);
      break;
  }
}

/**
* Intersection Observer callbacks
*/

const ioCallFunction = (init, element) => {
  // console.log(element);
  TORUS[init.fn].init(element)
  init.done = true
  // element.classList.remove("tor-hidden")
}

const INTERSECTION_OBSERVER_CALLBACK = (entries) => {
  for(let entry of entries) {
    let FX = entry.target.TORUS.Fx;
    let element = entry.target;

    if(entry.isIntersecting) {
      element.TORUS.visible = true;
      if (element.TORUS.init && !element.TORUS.init.done) {
        // console.log(element.TORUS.init);
        ioCallFunction(element.TORUS.init, element.TORUS.init.elements || element)
      }

      if (FX) {
        FX.properties.inview.isInview = true;
        FX.visible = true;
        // torus.Fx.isContainer && entry.target.setAttribute("data-tor-container", "in");
      }
    }
    else {
      element.TORUS.visible = false;

      if (FX) {
        FX.properties.inview.isInview = false;
        FX.visible = false;
        // torus.Fx.isContainer && entry.target.setAttribute("data-tor-container", "out");
      }
    }
  }

}

/**
* Inview Observer callbacks
*/

const INVIEW_OBSERVER_CALLBACK = (entries) => {
  for(let entry of entries) {
    let target = entry.target;
    let torusFx = entry.target.TORUS.Fx;

    if(entry.isIntersecting) {
      if(torusFx.properties.inview.inviewElement) {
        setTimeout(() => {
          target.classList.add("inview");
        }, 10);
      }
    }
    else {
      if(torusFx.properties.inview.inviewReset) {
        target.classList.remove("inview");
      }
    }
  }
}

/**
* Inview alternative if Intersection Observer is not supported
*/

const FALLBACK_INVIEW = (_this) => {
  _this = _this.TORUS.Fx;
  if(isInview(_this)) {
    setTimeout(() => {
      _this.element.classList.add("inview");
    }, 50);
  }
  else {
    if(_this.properties.inview.inviewReset) {
      _this.element.classList.remove("inview");
    }
  }
}

const FALLBACK_INTERSECTION = (_this) => {
  _this = _this.TORUS.Fx;
  if(isInview(_this)) {
    setTimeout(() => {
      _this.properties.inview.isInview = true;
    }, 50);
  }
  else {
    _this.properties.inview.isInview = false;
  }
}

const IV_SVG = (_this) => {
  if(_this.svg && _this.svg.parent && _this.svg.parent.getIntersectionList) {
    let rect;
    let svgElements;

    rect = _this.svg.parent.createSVGRect();
    rect.x = 0;
    // rect.y = SCROLLED.top - (_this.svg.parent.getBoundingClientRect().top + SCROLLED.top);
    rect.y = SCROLLED.top - _this.svg.parentTop;
    rect.width = WINDOW.width;
    rect.height = WINDOW.height;

    // svgElements = _this.svg.parent.getIntersectionList(rect, _this.element);
    svgElements = _this.svg.parent.getIntersectionList(rect, null);

    if(svgElements.length) {
      setTimeout(() => {
        _this.element.classList.add("inview");
      }, 50);
    }
    else {
      if(_this.properties.inview.inviewReset) {
        _this.element.classList.remove("inview");
      }
    }
  }
}

const IO_SVG = (_this) => {
  if(_this.svg && _this.svg.parent && _this.svg.parent.getIntersectionList) {
    let rect;
    let svgElements;

    rect = _this.svg.parent.createSVGRect();
    rect.x = 0;
    rect.y = SCROLLED.top - _this.svg.parentTop;
    rect.width = WINDOW.width;
    rect.height = WINDOW.height;

    svgElements = _this.svg.parent.getIntersectionList(rect, null);
    // svgElements = _this.svg.parent.getIntersectionList(rect, _this.element);

    if(svgElements.length) {
      _this.properties.inview.isInview = true;
      _this.visible = true;
    }
    else {
      _this.visible = false;
    }
  }
}

/**
* Intersection observer
*/

if(IO_SUPPORTED) {
  INVIEW_OBSERVER = new IntersectionObserver(INVIEW_OBSERVER_CALLBACK, {root: null});
  INTERSECTION_OBSERVER = new IntersectionObserver(INTERSECTION_OBSERVER_CALLBACK, {root: null, rootMargin: "100%"});
}

/**
 * ------------------------------------------------------------------------
 * Run given function on `window` `load` or `DOMContentLoaded`
 * ------------------------------------------------------------------------
 */

const onLoad = (initClass, method, elements) => {
  window.addEventListener(method, () => {
    if (elements) {
      for (const element of elements) {
        element.TORUS = element.TORUS || {}
        element.TORUS.init = element.TORUS.init || {}
        element.TORUS.init.fn = initClass

        OBSERVER("INTERSECTION", element);
      }
    }
    else {
      TORUS[initClass].init();
    }
  });
};

/**
 * ------------------------------------------------------------------------
 * Call given function in TORUS element
 * ------------------------------------------------------------------------
 */

const callFunction = (params) => {
  if(!params.elements) return;

  for (const element of getIterableElement(params.elements)) {
    if(element.TORUS && element.TORUS[params.object]) {
      element.TORUS[params.object][params.fn](params.argument);
    }
  }
};

/**
 * ------------------------------------------------------------------------
 * Initialize Class
 * ------------------------------------------------------------------------
 */

const initClass = (params) => {
  if(!params.elements) return;

  for(const element of params.elements) {
    if(!element.TORUS) {
      element.TORUS = element.TORUS || {};
    }
    element.TORUS[params.name] = new TORUS[params.name](element, params.options);
  }
};

/**
 * ------------------------------------------------------------------------
 * Check for the document layout (height) changes, then run functions
 * ------------------------------------------------------------------------
 */

const callAfterDocumentHeightChange = (elements) => {
  let bodyHeight;
  let lastBodyHeight = 0;

  const run = () => {
    let raf = requestAnimationFrame(() => {
      bodyHeight = document.body.clientHeight;

      if (bodyHeight !== lastBodyHeight) {
        lastBodyHeight = bodyHeight;
        run();
      }
      else {
        callFunction({elements: elements, object: "Fx", fn: "_addCSSVariables"});
        callFunction({elements: elements, object: "Fx", fn: "checkInview"});
        callFunction({elements: elements, object: "Fx", fn: "_setElementBounds"});
        cancelAnimationFrame(raf);
      }
    })
  }

  run()
}

/**
 * ------------------------------------------------------------------------
 * Set element height as CSS property
 * ------------------------------------------------------------------------
 */

const setHeight = (element) => {
  element.style.setProperty("--element-height", `${element.offsetHeight}px`);
}

/**
 * ------------------------------------------------------------------------
 * Replace semicolon
 * ------------------------------------------------------------------------
 */

const replaceSemicolon = (attributes) => {
  for (const match of attributes.match(/\((.*?)\)+/gm)) {
    attributes = attributes.replace(match, match.replace(/;+/g, "|"));
  }

  return attributes;
}

/**
 * ------------------------------------------------------------------------
 * Get scroll percentage
 * ------------------------------------------------------------------------
 */

const scrollPosition = (element) => {
  const parent = element.parentNode;
  let position = (element.scrollTop || parent.scrollTop) / (parent.scrollHeight - parent.clientHeight) * 100;

  return position;
}

/**
 * ------------------------------------------------------------------------
 * Expand cluster defined by `{}`
 * Example: hover:{grow fade--in} -> hover:grow hover:fade--in
 * ------------------------------------------------------------------------
 */

const expandCluster = (attributes) => {
  /** Match all attributes that has `trigger:` followed by attribute cluster defined by curly bracket `{...}` */
  let matches = attributes.match(/\b([^\s]+).({(.*?)})(__(.*?))(?=( |$))|\b([^\s]+).({(.*?)})/g);

  if (matches) {
    /** Loop trough all matches */
    for (let match of matches) {
      let attributesArray = [];

      /** Extract trigger defined by `trigger-name` and colon `:` */
      let trigger = /^(.*?)\:/.exec(match)[1];
      /** Extract everything between curly brackets `{}` and split by the space */
      let contents = /{(.*?)}+/g.exec(match)[1].split(" ");
      /** Extract options defined by `-_(<options>)` */
      let options = /__.*?(?= |$)/.exec(match);

      /** Combine every single content (attribute) with its trigger */
      for (let content of contents) {

        /** If has priority defined by `!` */
        if (/^!/.test(content)) {
          attributesArray.push(`!${trigger}:${content.replace("!", "")}${options ? options : ""}`);
        }
        else {
          attributesArray.push(`${trigger}:${content}${options ? options : ""}`);
        }
      }

      /** Replace original attribute cluster with separated attributes */
      attributes = attributes.replace(match, attributesArray.join(" "));
    }
  }

  return attributes;
}

/**
 * ------------------------------------------------------------------------
 * Replace all with regex
 * ------------------------------------------------------------------------
 */

String.prototype.replaceAll = function(value) {
  var replacedString = this;
  for (let x in value) {
    replacedString = replacedString.replace(new RegExp(x, "g"), value[x]);
  }
  return replacedString;
};

/**
 * ------------------------------------------------------------------------
 * FX Utilities
 * ------------------------------------------------------------------------
 */

/**
 * ------------------------------------------------------------------------
 * Get all attributes with properties defined by brackets [] or double underscore __
 * ------------------------------------------------------------------------
 */

const getAttributesWithValues = (attributes) => {
  return attributes.split(" ").filter(item => { return (/(\((.*?)\))|(__\()+/g).test(item) });
};

/**
 * ------------------------------------------------------------------------
 * Intersection Observer fallback
 * Check if element is in viewport.
 * Including offset
 * ------------------------------------------------------------------------
 */

const isInview = (_this) => {
  let bounding = _this.element.getBoundingClientRect();
  let inview_offset;

  if(_this.properties.inview && _this.properties.inview.inview_offset) {
    inview_offset = _this.properties.inview.inview_offset;
  }
  else if(_this.properties.customTransform && _this.properties.customTransform.inview) {
    inview_offset = _this.properties.customTransform.inview.inview_offset;
  }
  else {
    inview_offset = 0;
  }

  let top = (WINDOW.height / 100) * inview_offset + (bounding.top || _this.bounds.offsetTop - SCROLLED.top);
  let bottom = bounding.bottom || _this.bounds.offsetTop + _this.bounds.height - SCROLLED.top;
  let left = bounding.left || _this.bounds.offsetLeft;
  let right = bounding.right || _this.bounds.offsetLeft + _this.bounds.width;

  return ( top < WINDOW.height && bottom > 0 && left < WINDOW.width && right > 0 );
};

/**
 * ------------------------------------------------------------------------
 * Intersection Observer fallback
 * Check if element is in viewport.
 * Based on actual dimensions (including transform) with added top and bottom offset (WINDOW.height/2)
 * ------------------------------------------------------------------------
 */

const isInviewActual = (element) => {
  let bounding = element.getBoundingClientRect();
  let isInview = (bounding.top - WINDOW.height/2) < WINDOW.height && (bounding.bottom + WINDOW.height/2) > 0 && bounding.left < WINDOW.width && bounding.right > 0;
  element.parentInview = isInview;
};

/**
 * ------------------------------------------------------------------------
 * Calculate percents based on mouse move
 * ------------------------------------------------------------------------
 */

const mousePercents = (_this) => {
  return {
    "middle": {
      mx: 1 - Math.abs((WINDOW.width/2-MOUSE.x)  / (WINDOW.width/2)),
      my: Math.round(( 1 - Math.abs((WINDOW.height/2-MOUSE.y) / (WINDOW.height/2))) * 1000) / 1000,
      m:  Math.round(( 1 - Math.sqrt(Math.pow(WINDOW.width/2-MOUSE.x,2) + Math.pow(WINDOW.height/2-MOUSE.y,2)) / Math.sqrt(Math.pow(WINDOW.width/2,2) + Math.pow(WINDOW.height/2,2))) * 1000) / 1000,
    },
    "start": {
      mx: Math.round(( 1 - (WINDOW.width-MOUSE.x) / (WINDOW.width)) * 1000) / 1000,
      my: Math.round(( 1 - (WINDOW.height-MOUSE.y) / (WINDOW.height)) * 1000) / 1000,
      m:  Math.sqrt(Math.pow(MOUSE.x,2) + Math.pow(MOUSE.y,2)) / Math.sqrt(Math.pow(WINDOW.width,2) + Math.pow(WINDOW.height,2))
    },
    "continuous": {
      mx: Math.round(( 1 - (WINDOW.width/2-MOUSE.x)  / (WINDOW.width/2)) * 1000) / 1000,
      my: Math.round(( 1 - (WINDOW.height/2-MOUSE.y) / (WINDOW.height/2)) * 1000) / 1000,
    },
    "self": {
      mx: _this && 1 - Math.abs((MOUSE.x-_this.bounds.centerX)/_this.bounds.maxXSide),
      my: _this && 1 - Math.abs((MOUSE.y-_this.bounds.centerY)/_this.bounds.maxYSide),
      m:  _this && (getMouseHoverPosition(_this, _this.bounds.centerX, _this.bounds.centerY))
    },
    "self-continuous": {
      mx: _this && 1 + ((MOUSE.x-_this.bounds.centerX)/_this.bounds.maxXSide),
      my: _this && 1 + ((MOUSE.y-_this.bounds.centerY)/_this.bounds.maxYSide),
      // m:  _this && (getMouseHoverPosition(_this, _this.bounds.centerX/_this.bounds.maxXSide, _this.bounds.centerY/_this.bounds.maxYSide))
    },
    "parallax": {
      mx: ((MOUSE.x-WINDOW.width/2) / (WINDOW.width/2)),
      my: ((MOUSE.y-WINDOW.height/2) / (WINDOW.height/2))
    }
  }
};

/**
 * ------------------------------------------------------------------------
 * Get the shortest distance from given centerX, centerY to mouse pointer
 * ------------------------------------------------------------------------
 */

const getMouseHoverPosition = (_this, centerX, centerY) => {
  return 1 - Math.abs( Math.sqrt(Math.pow(Math.abs(centerX-MOUSE.x),2) + Math.pow(Math.abs(centerY-MOUSE.y),2)) / _this.bounds.maxDiagonal );
};

/**
 * ------------------------------------------------------------------------
 * Calculate percents based on scroll
 * ------------------------------------------------------------------------
 */

const scrollPercents = (_this, offset, offsetStart, afterScrolledStart, afterScrolledEnd) => {
  let offset_1;
  let offset_2;
  let parallaxPercents;
  let regularPercents;
  let afterScrollDifference;
  let afterStart;
  let afterEnd;

  let usingOffsetAmount = (offset || offsetStart) ? true : false;
  let usingScrollAmount = (afterScrolledStart || afterScrolledEnd) ? true : false;

  /**
   * If using offset - based on how much of element bounds is visible in the viewport
   */

  if(usingOffsetAmount) {
    if(offsetStart) {
      offset_1 = (WINDOW.height/100) * offsetStart;
      offset_2 = offset === "middle" ? offsetStart * ((50+offsetStart)/100) : offsetStart;
    }
    else {
      offset_1 = 0;
      offset_2 = 0;
    }
  }

  /**
   * If using scroll amount - based on how much user scrolled down
   */

  if(usingScrollAmount) {
    if(!afterScrolledStart) {
      afterEnd = afterScrolledEnd;
    }
    else {
      afterStart = afterScrolledEnd;
      afterEnd = afterScrolledStart;
    }

    if(afterScrolledStart && afterScrolledEnd) {
      afterScrollDifference = afterScrolledEnd - afterScrolledStart;
    }
  }

  /**
   * Parallax scroll behavior
   */

  // TODO: make sure that parallax is the same in both cases (when element is above the fold and under)
  // if(_this.bounds.offsetTop <  WINDOW.height) {
  //   parallaxPercents = 1 + (SCROLLED.top)/(200);
  // }
  // else {
  //   parallaxPercents = 1 - (WINDOW.height/2 - (WINDOW.height - (_this.bounds.offsetTop - SCROLLED.top + _this.bounds.height/2)) ) / ((WINDOW.height/2));
  // }

  parallaxPercents = 1 - (WINDOW.height / 2 - (WINDOW.height - (_this.bounds.offsetTop - SCROLLED.top + _this.bounds.height / 2))) / ((WINDOW.height / 2));

  /**
   * Regular scroll behavior
   */

  // TODO:
  /** Element's offset is smaller then window height = it's immediately visible in the viewport */
  // if(_this.bounds.offsetTop <  WINDOW.height) {
  //   if(usingOffsetAmount) {
  //     regularPercents = (-1 + SCROLLED.top)/(_this.bounds.offsetTop);
  //   }
  //   if(usingScrollAmount) {
  //     regularPercents = (-1 + SCROLLED.top - afterEnd) / (afterScrollDifference || (WINDOW.height/2));
  //   }
  // }
  // else {
    if(usingOffsetAmount) {
      regularPercents = (-0.0001 + WINDOW.height - (_this.bounds.offsetTop - SCROLLED.top + offset_1) ) / ( ((WINDOW.height + (offset === "middle" ? _this.bounds.height : 0))/100) * ((offset === "middle" ? 50 : offset) - offset_2) );
    }
    if(usingScrollAmount) {
      regularPercents = (SCROLLED.top - afterEnd) / (afterScrollDifference || (WINDOW.height/2));
    }
  // }


  return {
    regular: {
      sy: _this && regularPercents,
    },
    parallax: {
      sy: _this && parallaxPercents
    }
  }
};

/**
 * ------------------------------------------------------------------------
 * Get element bounds
 * ------------------------------------------------------------------------
 */

const getBounds = (_this) => {
  let bounds          = _this.element.getBoundingClientRect()
  let offset          = elementOffset(_this.element)
  let top             = Math.round(bounds.top);
  let bottom          = Math.round(bounds.bottom);
  let left            = Math.round(bounds.left);
  let right           = Math.round(bounds.right);
  let offsetTop       = offset.offsetTop;
  let offsetLeft      = offset.offsetLeft;
  let height          = _this.element.clientHeight /* || _this.element.getBoundingClientRect().height */;
  let width           = _this.element.clientWidth /* || _this.element.getBoundingClientRect().height */;
  let centerX         = offsetLeft + width/2 - SCROLLED.left;
  let centerY         = offsetTop  + height/2 - SCROLLED.top;

  return {
    top,
    bottom,
    left,
    right,
    offsetTop,
    offsetLeft,
    width,
    height,
    centerX,
    centerY,
  }
};

/**
 * ------------------------------------------------------------------------
 * Set element bounds to "bounds" object
 * ------------------------------------------------------------------------
 */

const setBounds = (_this) => {
  let GB               	 			= getBounds(_this);
  _this.bounds								= _this.bounds || {};
  _this.bounds.top            = GB.top;
  _this.bounds.bottom         = GB.bottom;
  _this.bounds.left           = GB.left;
  _this.bounds.right          = GB.right;
  _this.bounds.offsetTop      = GB.offsetTop;
  _this.bounds.offsetLeft     = GB.offsetLeft;
  _this.bounds.width          = GB.width;
  _this.bounds.height         = GB.height;
  _this.bounds.centerX        = GB.centerX;
  _this.bounds.centerY        = GB.centerY;
  _this.bounds.maxDiagonal    = Math.round(getMaxSide(_this).corner);
  _this.bounds.maxXSide       = getMaxSide(_this).xSide;
  _this.bounds.maxYSide       = getMaxSide(_this).ySide;
};

/**
 * ------------------------------------------------------------------------
 * BETA - SVG needs more work
 *
 * Get element original offset without any transforms
 * ------------------------------------------------------------------------
 */

const elementOffset = (obj) => {
  let top 	= 0;
  let left 	= 0;
  let element = obj;
  let parentSVG = {};

  /** SVG alone */
  if (obj.nodeName === "svg" && !obj.ownerSVGElement) {
    element = obj;
  }

  /** SVG inside SVG parent */
  if(obj.nodeName === "svg" && obj.ownerSVGElement) {
    element = obj.closest(".tk-svg") || obj.ownerSVGElement;
  }

  /** SVG child */
  if(obj.ownerSVGElement) {
    parentSVG.element = obj.closest(".tk-svg") || obj.ownerSVGElement;
    parentSVG.heightRatio = obj.ownerSVGElement.clientHeight / obj.ownerSVGElement.height.baseVal.value;
    parentSVG.widthRatio = obj.ownerSVGElement.clientWidth / obj.ownerSVGElement.width.baseVal.value;
  }

  /** If element is SVG child */
  if(obj.ownerSVGElement && obj.nodeName !== "defs") {
    if(obj.ownerSVGElement.clientHeight === 0 && obj.ownerSVGElement.clientHeight === 0) {
      obj.classList.add("hidden-svg-child");
    }
    else {
      if(obj.nodeName === "g") {
        let element = obj;
        let tempTop = 0;
        let tempLeft = 0;
        let parentTop = 0;
        let parentLeft = 0;

        do {
          tempTop = element.getBBox().y * parentSVG.heightRatio;
          tempLeft = element.getBBox().x * parentSVG.widthRatio;
          element = element.firstElementChild;
        } while ( (!tempTop || !tempLeft) && element );

        element = parentSVG.element;

        do {
          parentTop += element.offsetTop || 0;
          parentLeft += element.offsetLeft || 0;
          element = element.offsetParent;
        } while (element);

        top = parentTop + tempTop;
        left = parentLeft + tempLeft;
      }
      else {
        let parentTop = 0;
        let parentLeft = 0;

        do {
          parentTop += element.offsetTop || 0;
          parentLeft += element.offsetLeft || 0;
          element = element.offsetParent;
        } while (element);

        top = parentTop + (obj.getBBox().y * parentSVG.heightRatio);
        left = parentLeft + (obj.getBBox().x * parentSVG.widthRatio);

      }
    }
  }
  else {
    do {
      top += element.offsetTop || 0;
      left += element.offsetLeft || 0;
      element = element.offsetParent;
    } while (element);
  }

  return {
    offsetTop: 	top,
    offsetLeft:	left,
    heightRatio: parentSVG.heightRatio || null,
    widthRatio: parentSVG.widthRatio || null,
  };
};

/**
 * ------------------------------------------------------------------------
 * Calculate the longest distance from element center to one of the screen corners
 * ------------------------------------------------------------------------
 */

const getMaxSide = (_this) => {
  let lt = Math.sqrt(Math.pow(_this.bounds.centerX, 2) + Math.pow(_this.bounds.centerY, 2));
  let lb = Math.sqrt(Math.pow(_this.bounds.centerX, 2) + Math.pow(WINDOW.height - _this.bounds.centerY, 2));
  let rt = Math.sqrt(Math.pow(WINDOW.width -_this.bounds.centerX, 2) + Math.pow(_this.bounds.centerY, 2));
  let rb = Math.sqrt((Math.pow(WINDOW.width -_this.bounds.centerX, 2) + Math.pow(WINDOW.height - _this.bounds.centerY, 2)));
  let ls = _this.bounds.centerX;
  let rs = WINDOW.width - _this.bounds.centerX;
  let ts = _this.bounds.centerY;
  let bs = WINDOW.height - _this.bounds.centerY;
  let corner = Math.max(...[lt,lb,rt,rb]);
  let xSide = Math.max(...[ls, rs]);
  let ySide = Math.max(...[ts, bs]);

  return { corner, xSide, ySide };
};

/**
 * ------------------------------------------------------------------------
 * Set inline CSS based on "live values"
 * ------------------------------------------------------------------------
 */

const setLiveCSS = (_this) => {
  let CT = _this.properties.customTransform;

  let Y = [];
  let X = [];
  let transformArray = [];
  let filterArray = [];

  let target;
  let thisObject = {};

  for (const action of ["mouse", "scroll", "sensor"]) {
    if (CT[action]) {
      // let counter = 0;

      for (const [key, value] of Object.entries(CT[action])) {
        let live = value.currentValues.live;

        target = value.options.target || [_this.element];

        // if (value.options.target) {
        // 	thisObject.targetOthers = thisObject.targetOthers || {};
        // 	thisObject.targetOthers.element = value.options.target;
        // 	console.log(counter);
        // 	counter++;
        // }
        // else {
        // 	thisObject.targetThis = thisObject.targetThis || {};
        // 	thisObject.targetThis.element = [_this.element];
        // }

        if (live) {
          switch (value.cssPropertyType) {
            case "transform":
              transformArray.push(value);
              break;

            case "filter":
              filterArray.push(value);
              break;

            default:
              // target.forEach(element => element.style.setProperty(value.name, value.multiValues.cssFunction ? `${value.multiValues.cssFunction}(${live})` : live, "important"));
              _this.element.style.setProperty(value.name, value.multiValues.cssFunction ? `${value.multiValues.cssFunction}(${live})` : live, "important")
              break;
          }
        }

      }
    }
  }

  if (filterArray.length) {
    _this.element.style.setProperty("filter", filterCSS(filterArray).live, "important");
    // target.forEach(element => element.style.setProperty("filter", filterCSS(filterArray).live, "important"));
  }

  if (transformArray.length) {
    _this.element.style.setProperty("transform", transformCSS(transformArray).live, "important");
    // target.forEach(element => element.style.setProperty("transform", transformCSS(transformArray).live, "important"));
  }

  function transformCSS(array) {
    let CSS  = {};

    for(const item of array) {
      CSS[item.name] = item.currentValues.live;

      if(item.name === "translateY") {
        Y.push(item.currentValues.live);
      }
      if(item.name === "translateX") {
        X.push(item.currentValues.live);
      }
    }

    CSS.translateY = Y.length && `calc(${Y.join(" + ")})`;
    CSS.translateX = X.length && `calc(${X.join(" + ")})`;

    if (_this.properties.static["perspective-self"]) {
      CSS.perspective = `${_this.properties.static["perspective-self"].value}${_this.properties.static["perspective-self"].unit || "px"}`
    }

    return {
      live:
      `
          perspective(${CSS.perspective || "1000px"})
          translate3d(${CSS.translateX || "0px"}, ${CSS.translateY || "0px"}, ${CSS.translateZ || "0px"})
          rotateX(${CSS.rotateX || "0deg"})
          rotateY(${CSS.rotateY || "0deg"})
          rotateZ(${CSS.rotate ? CSS.rotate : CSS.rotateZ || "0deg"})
          scale(${CSS.scaleX ? CSS.scaleX : CSS.scale || 1}, ${CSS.scaleY ? CSS.scaleY : CSS.scale || 1})
          skew(${CSS.skew ? CSS.skew : CSS.skewX || "0deg"}, ${CSS.skewY && CSS.skewY || "0deg"})
          `
    }
  }

  function filterCSS(array) {
    let filter = [];

    for (const item of array) {
      filter.push(`${item.name}(${item.currentValues.live})`);
    }

    return {
      live: filter.join(" "),
    }
  }

};

export {
  TORUS,
  WINDOW,
  SCROLLED,
  MOUSE,
  SENSOR,
  REFRESH_ON_RESIZE,
  OBSERVER,
  getData ,
  optimizeAttribute,
  removeSpaces,
  getProperties,
  getCustomTransforms,
  getValueForCurrentResolution,
  getResolutions,
  getStyleValue,
  getCurrentResolution,
  getIterableElement,
  callFunction,
  callAfterDocumentHeightChange,
  initClass,
  onLoad,
  isCurrentResolution,
  setHeight,
  scrollPosition,
  replaceSemicolon,
  expandCluster,
  wrapElement,

  // FX Utilities

  getAttributesWithValues,
  isInview,
  isInviewActual,
  mousePercents,
  getMouseHoverPosition,
  scrollPercents,
  getBounds,
  setBounds,
  elementOffset,
  getMaxSide,
  setLiveCSS,
}
