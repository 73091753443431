/**
 * ------------------------------------------------------------------------
 * Class action
 * (c) Torus Kit
 * ------------------------------------------------------------------------
 */

import TORUS from "./namespace";

import {
  getIterableElement,
  initClass,
  optimizeAttribute,
  getProperties,
  scrollPosition,
  expandCluster,
  SCROLLED,
  onLoad,
  callFunction,
} from "./util";

TORUS.ClassAction = class {
  constructor(element, options) {
    this.element = element;
    this.attributes = expandCluster(optimizeAttribute(this.element.getAttribute("data-tor-class-action")));
    this.element.setAttribute("data-tor-class-action", this.attributes);
    this.triggers = this.triggers || {};
    this.raf;

    // this.createTriggers();
    // this.addListeners();
  }

  /**
  * ------------------------------------------------------------------------
  * Create actions
  * ------------------------------------------------------------------------
  */

  createTriggers() {

    /** Temporary object that stores triggers */
    const triggerObject = {};

    /** Object that stores trigger variants */
    for (const name of ["click", "scroll", "timeout", "hover"]) {
      triggerObject[name] = {};

      /** Array of class actions (methods) */
      for (const method of ["add", "remove", "toggle"]) {
        triggerObject[name][method] = [];
      }
    }

    /**
     * Separate each attribute to corresponding array
     */

    /** Loop through all `data-tor-class-action` attributes*/
    for (const attribute of this.attributes.split(" ")) {

      /** Get trigger and attribute properties */
      const triggerGP = getProperties(attribute.split(":")[0], true);
      const attributeGP = getProperties(attribute, true);

      /** If trigger name (defined in `triggerGP.name`) matches one from the array,
       * add the whole attribute to corresponding array */
      for (const name of ["click", "scroll", "timeout", "hover"]) {
        if (triggerGP.name === name) {
          triggerObject[name][attributeGP.name].push(attribute);
        }
      }
    }

    /**
     * Create `this.triggers` object
     */

    for (const trigger of ["click", "scroll", "timeout", "hover"]) {
      if (this.attributes.includes(trigger)) {

        /** Creates `trigger` object. Example: `this.triggers.click` */
        this.triggers[trigger] = this.triggers[trigger] || {};

        /** Loop through all pushed entries in `triggerObject.click` object */
        for (const [method, values] of Object.entries(triggerObject[trigger])) {

          /** If the array is not empty */
          if (values.length) {

            /** Create `method` object. Example `this.triggers.click.add` */
            this.triggers[trigger][method] = this.triggers[trigger][method] || {};

            /** Fill the `method` object with the data extracted from each attribute defined in `data-tor-class-action` */
            for (const [i, attribute] of triggerObject[trigger][method].entries()) {
              const triggerGP = getProperties(attribute.replace("|", ";").split(":")[0], true);
              const attributeGP = getProperties(attribute.split(":")[1], true);

              /** `this.triggers.click.add.0` */
              this.triggers[trigger][method][i] = this.triggers[trigger][method][i] || {};
              this.triggers[trigger][method][i].class = attributeGP.value.split(",");
              this.triggers[trigger][method][i].priority = triggerGP.priority;
              this.triggers[trigger][method][i].target =
                attributeGP.options.target
                  ? attributeGP.options.target
                  : [this.element];

              /** Additional parameters */
              switch (trigger) {
                case "scroll":
                  this.triggers.scroll[method][i].unit = triggerGP.unit;
                  this.triggers.scroll[method][i].revert = attributeGP.options.revert || null;
                  if (triggerGP.unit === "%") {
                    this.triggers.scroll[method][i].offsetStart = document.body.clientHeight * (triggerGP.value.start / 100) || document.body.clientHeight * (triggerGP.value / 100);
                    this.triggers.scroll[method][i].offsetEnd = document.body.clientHeight * (triggerGP.value.end / 100) || null;
                  }
                  else {
                    this.triggers.scroll[method][i].offsetStart = triggerGP.value.start || triggerGP.value;
                    this.triggers.scroll[method][i].offsetEnd = triggerGP.value.end || null;
                  }
                  break;

                case "timeout":
                  /** If timeOut has start/end values separated by semicolon ";" */
                  this.triggers.timeout[method][i].start = triggerGP.value.start || triggerGP.value;

                  /** Default value for end is null */
                  this.triggers.timeout[method][i].end = triggerGP.value.end || null;
                  break;
              }


            }
          }
        }
      }
    }
  }

  /**
   * ------------------------------------------------------------------------
   * Add listeners
   * ------------------------------------------------------------------------
   */

  addListeners() {
    if (/click/.test(this.attributes)) {
      this.element.addEventListener("click", this.onClick.bind(this));
    }

    if (/hover/.test(this.attributes)) {
      this.element.addEventListener("mouseenter", this.onMouseEnter.bind(this));
      this.element.addEventListener("mouseleave", this.onMouseLeave.bind(this));
    }

    if (/scroll/.test(this.attributes)) {
      window.addEventListener("scroll", this.onScroll.bind(this), { passive: true });
      this.onScroll();
    }

    if (/timeout/.test(this.attributes)) {
      this.onTimeout();
    }
  }

  /**
   * ------------------------------------------------------------------------
   * On timeout actions
   * ------------------------------------------------------------------------
   */

  onTimeout() {
    for (const [method, attributes] of Object.entries(this.triggers.timeout)) {
      for (const attribute of Object.values(attributes)) {
        attribute.time = setTimeout(() => {

          /** Trigger active state */
          this.triggerNewState(attribute, method);

          /** If timeOut has end value */
          if (attribute.end) {
            attribute.time = setTimeout(() => {

              /** Trigger state back to inactive (original) */
              this.triggerOldState(attribute, method);

              clearTimeout(attribute.time);
            }, attribute.end);
          }
          else {
            clearTimeout(attribute.time);
          }

        }, attribute.start);
      }
    }
  }

  /**
   * ------------------------------------------------------------------------
   * On scroll actions
   * ------------------------------------------------------------------------
   */

  onScroll() {
    this.raf && window.cancelAnimationFrame(this.raf);
    this.raf = window.requestAnimationFrame(function() {

      SCROLLED.top = window.scrollY;

      for (const [method, attributes] of Object.entries(this.triggers.scroll)) {
        for (const [i, attribute] of Object.entries(attributes)) {
          let position;
          let isInside;

          /** Count scroll position */
          if (this.triggers.scroll[method][i].unit === "%") {
            position = (scrollPosition(document.body) / 100) * document.body.clientHeight;
          }
          else {
            position = SCROLLED.top;
          }

          /** Check if the scroll position is greater that defined offset, or its between `offsetStart` and `offsetEnd` */
          if (attribute.offsetEnd) {
            isInside = position >= attribute.offsetStart && position < attribute.offsetEnd;
          }
          else {
            isInside = position >= attribute.offsetStart;
          }

          if (isInside) {
            if (!attribute.scrolled) {

              for (const [method, attributes] of Object.entries(this.triggers.scroll)) {
                for (const attribute of Object.values(attributes)) {
                  this.triggerNewState(attribute, method);
                }
              }
              attribute.scrolled = true;
            }
          }
          else {
            if (attribute.scrolled) {
              if (attribute.offsetEnd) {
                this.triggerOldState(attribute, method);
              }
              else {
                if (attribute.revert) {
                  this.triggerOldState(attribute, method);
                }
              }
              attribute.scrolled = false;
            }
          }

        }
      }
    }.bind(this));
  }

  /**
   * ------------------------------------------------------------------------
   * On click actions
   * ------------------------------------------------------------------------
   */

  onClick() {
    for (const [method, attributes] of Object.entries(this.triggers.click)) {
      for (const attribute of Object.values(attributes)) {
        this.triggerNewState(attribute, method);
      }
    }
  }

  onMouseEnter() {
    for (const [method, attributes] of Object.entries(this.triggers.hover)) {
      for (const attribute of Object.values(attributes)) {
        this.triggerNewState(attribute, method);
      }
    }
  }

  onMouseLeave() {
    for (const [method, attributes] of Object.entries(this.triggers.hover)) {
      for (const attribute of Object.values(attributes)) {
        this.triggerOldState(attribute, method);
      }
    }
  }

  /**
   * ------------------------------------------------------------------------
   * Trigger New state
   * Will add new classes from add[] action and remove the original ones from remove[] action
   * ------------------------------------------------------------------------
   */

  triggerNewState(attribute, method) {
    if(this.disabled) {
      return;
    }

    for (const target of attribute.target) {
      if (attribute.priority) {
        setTimeout(() => {
          // target.classList[method](...attribute.class);
          [...attribute.class].map(_class => target.classList[method](_class));
        }, 10);
      }
      else {
        [...attribute.class].map(_class => target.classList[method](_class));
        // target.classList[method](...attribute.class);
        // let classes = [...attribute.class];
        // if (method === "toggle") {
        //   classes.map(_class => target.classList[method](_class));
        // }
      }
    }
  }

  /**
   * ------------------------------------------------------------------------
   * Trigger Old (reverted/original) state
   * Will remove new classes from add[] action and add back the original ones from remove[] action
   * ------------------------------------------------------------------------
   */

  triggerOldState(attribute, method) {
    if (this.disabled) {
      return;
    }

    let newMethod;

    switch (method) {
      case "add":
        newMethod = "remove";
        break;

      case "remove":
        newMethod = "add";
        break;

      default:
        newMethod = "toggle";
        break;
    }

    for (const target of attribute.target) {
      target.classList[newMethod](...attribute.class);
    }
  }

  static createTriggers(elements) {
    callFunction({elements: elements, object: "ClassAction", fn: "createTriggers"});
  }

  static addListeners(elements) {
    callFunction({elements: elements, object: "ClassAction", fn: "addListeners"});
  }

  /**
   * ------------------------------------------------------------------------
   * Init
   * ------------------------------------------------------------------------
   */

  static init(elements, options) {
    elements = getIterableElement(elements || "[data-tor-class-action]");
    initClass({name: "ClassAction", elements: elements, options: options});

    /** Batch */
    this.createTriggers(elements);
    this.addListeners(elements);
  }
}

// TORUS.ClassAction.init();
onLoad("ClassAction", "DOMContentLoaded", getIterableElement("[data-tor-class-action]"));

export default TORUS.ClassAction;