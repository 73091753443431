/**
 * ------------------------------------------------------------------------
 * Range progress
 * (c) Torus Kit
 * ------------------------------------------------------------------------
 */

import TORUS from "./namespace";

import {
  getIterableElement,
  initClass,
  onLoad,
} from "./util";

TORUS.Range = class {
  constructor(element) {
    this.element = element;
    this.countRange();
    this.addEventListeners();
  }

  countRange() {
    let min = this.element.min || 0;
    let max = this.element.max || 100;
    let step = this.element.step || 1;
    let range = max - min;
    let bg = `calc((${ ((100/range) * step) } * 1%) - (${(step/range)} * 1em))`;

    this.currentValue = ((this.element.value - min) / (max - min)) * 100;
    this.element.style.setProperty("--range-bg", bg);
    this.element.style.setProperty("--range", `${this.currentValue}%`);
  }

  addEventListeners() {
    this.element.addEventListener("input", function() {
      this.countRange();
    }.bind(this), false);
  }

  static init(elements) {
    // if(!(/AppleWebKit/).test(navigator.userAgent)) {
    // 	return;
    // }

    elements = getIterableElement(elements || ".form-range-tick-marks");
    initClass({name: "Range", elements: elements});
  }
}

// TORUS.Range.init();
onLoad("Range", "DOMContentLoaded");

export default TORUS.Range;

