import TORUS from "./namespace";

import {
  OBSERVER,
  getIterableElement,
  initClass,
  callFunction,
  optimizeAttribute,
  getAttributesWithValues,
  getData,
  onLoad,
  SCROLLED,
} from "./util";

import {
  CONTAINERS,
} from "./fx.globals";

/**
 * ------------------------------------------------------------------------
 * Effects
 * (c) Torus Kit
 * ------------------------------------------------------------------------
 */

/**
 * ------------------------------------------------------------------------
 * TORUS.FxGroup Class init
 * ------------------------------------------------------------------------
 */

TORUS.FxGroup = class {
  constructor(element) {
    this.element = element;
    this.isGroupFx = this.element.hasAttribute("data-tor-fx-group");

    // if(this.element.hasAttribute("data-tor-fx-group")) {
    //   this.setAttributes();
    // }
  }

  /**
   * ------------------------------------------------------------------------
   * Set attributes
   * ------------------------------------------------------------------------
   */

  setAttributes() {
    if (!this.isGroupFx) {
      return
    }

    this.attributes_all = optimizeAttribute(this.element.getAttribute("data-tor-fx-group"));
    this.attributes_props = getAttributesWithValues(this.attributes_all);

    this.attributes_all = this.attributes_all.replace(/ +/g, "⠀");

    /** Replace the semicolon `;` with the temporary pipe `|` */
    if (/;/.test(this.attributes_all) ) {
      for (const match of this.attributes_all.match(/\((.*?)\)+/g)) {
        this.attributes_all = this.attributes_all.replace(match, match.replace(/;/, "|") );
      }
    }

    this.element.setAttribute("data-tor-fx-group", this.attributes_all);
    this.setProperties();
  }

  /**
   * ------------------------------------------------------------------------
   * Set properties
   * ------------------------------------------------------------------------
   */

  setProperties() {
    this.properties =  {};
    this.group = {}
    let elementsCount = 0;

    for (const [key, value] of Object.entries(this.attributes_all.split(";")) ) {
      if (value) {
        let countingValue = 0;

        this.properties[key] = this.properties[key] || {};

        let target = value.split("=>")[0].replace("⠀", " ");
        let attributes = value.split("=>")[1].split("⠀");

        this.properties[key].target = target;
        this.properties[key].attributes = attributes;

        // this.group[countingValue] = {};
        // this.group[countingValue].element = target;
        // this.group[countingValue].attributes = attributesArray.join(" ");


        /** Loop through all targets */
        for (const element of this.element.querySelectorAll(this.properties[key].target) ) {
          let attributesArray = [];

          for(let attribute of this.properties[key].attributes) {

            /** Find all values with `()` */
            let matches = attribute.match(/\((.*?)\)+/g);

            if (matches) {

              /** Loop through all matches */
              for (let [i, match] of Object.entries(matches)) {
                let startValues;
                let endValues;
                let startValuesArray = [];
                let endValuesArray = [];

                /** Extract value from `()` */
                match = /(?!\w|\()((.*?)(?=\)))*/.exec(match)[0];
                // match = /\((.*?)\)+/g.exec(match)[1];

                /**
                 * IF: "match" has two arguments
                 */
                if(match.split("|")[1]) {
                  /** Split to "start" and "end" values */
                  startValues = match.split("|")[0];
                  endValues 	= match.split("|")[1];

                  /** Replace the original start/end values with temporary $startValues and $endValues */
                  attribute = attribute.replace(startValues, `$startValues${i}`).replace(endValues, `$endValues${i}`);
                }
                /**
                 * ELSE: there is only one "end" argument
                 */
                else {
                  endValues = match;
                  /** Replace the original end value with temporary $endValues */
                  attribute = attribute.replace(endValues, `$endValues${i}`);
                }

                /**
                 * START
                 *
                 * Split all "start" values and loop through them
                 */

                if(startValues) {
                  for(let startValueSplitted of startValues.split("⠀")) {

                    /** If Value has "+/-/~" sign for counting */
                    if(/\+|\-|~+/g.test(startValueSplitted)) {
                      let GU = getData(startValueSplitted);

                      if (GU.random) {
                        startValuesArray.push(`${GU.resolution || ""}${(GU.value || 0) + (Math.round(Math.random() * (GU.counting - 0) + 0)) || 0}${GU.unit || ""}`);
                      }
                      else {
                        startValuesArray.push(`${GU.resolution || ""}${(GU.value || 0) + (countingValue * (GU.counting || 0))}${GU.unit || ""}`);
                      }
                    }
                    /** Else Value is static - will not count */
                    else {
                      startValuesArray.push(startValueSplitted);
                    }
                  }
                }

                /**
                 * END
                 * Has only one value. Example: delay(50ms) or with counting delay(/+100ms/)
                 * Split all "end" values and loop through them
                 */
                for(let endValueSplitted of endValues.split("⠀")) {
                  let GU = getData(endValueSplitted);

                  /** If Value has "+/-" sign for counting */
                  if (/\+|\-|~+/g.test(endValueSplitted)) {
                    if (GU.random) {
                      endValuesArray.push(`${GU.resolution || ""}${(GU.value || 0) + (Math.round(Math.random() * (GU.counting - 0) + 0)) || 0}${GU.unit || ""}`);
                    }
                    else {
                      endValuesArray.push(`${GU.resolution || ""}${ (GU.value || 0) + (countingValue * (GU.counting || 0)) }${GU.unit || ""}`);
                    }
                  }
                  /** Else Value is static - will not count */
                  else {
                    endValuesArray.push(endValueSplitted);
                  }
                }

                /** Replace original attribute values with the new values */
                attribute = attribute.replace(`$endValues${i}`, endValuesArray.join("⠀")).replace(`$startValues${i}`, startValuesArray.join("⠀")).replace(/\|+/g, ";");
              }
            }

            /** Add new attributes into array */
            attributesArray.push(attribute);
          }

          this.group[elementsCount] = {};
          this.group[elementsCount].element = element;
          this.group[elementsCount].attributes = attributesArray.join(" ");

          elementsCount += 1;
          countingValue += 1;

          // element.classList.add("tr-none");
          // TORUS.Fx.add(element, attributesArray.join(" "));
          // setTimeout(() => { element.classList.remove("tr-none"); }, 100);
        }
      }
    }

    for (const item of Object.values(this.group)) {
      if(item.element.hasAttribute("data-tor-fx")) {
        item.element.setAttribute("data-tor-fx", [ ...new Set([...item.attributes.split(" "), ...item.element.getAttribute("data-tor-fx").split(" ")]) ].join(" "));
      }
      else {
        item.element.setAttribute("data-tor-fx", item.attributes);
      }
    }

    // TODO: Refresh on add
    // if( /(onscroll:)|(onmouse:)|(onsensor:)|(iv:.*\[)/g.test(this.attributes_props) ) {
      // TORUS.Fx.refresh();
    // }
  }

  /**
   * Add data-tor-fx-group property
   */

  add(attribute) {
    if(this.element.hasAttribute("data-tor-fx-group")) {
      this.element.setAttribute("data-tor-fx-group", [ ...new Set([...attribute.split(" "), ...this.element.getAttribute("data-tor-fx-group").split(" ")]) ].join(" "));
    }
    else {
      this.element.setAttribute("data-tor-fx-group", attribute);
    }

    this.setAttributes();
  }

  /**
   * Remove data-tor-fx-group property
   */

  remove(attribute) {
    if(!attribute) {
      return;
    }

    for(const attrib of attribute.split(" ")) {
      let element;
      let attribToRemove;
      if( (/\(\(+/g).test(attrib) ) {
        attribToRemove = (/\(([^())]+)\)+/g).exec(attrib.split("->")[1]).pop();
        element = this.element.querySelectorAll(`[data-tor-fx~="${ attribToRemove }"]`);
        TORUS.Fx.remove(element, attribToRemove);
      }
      else {
        attribToRemove = attrib;
        element = this.element.querySelectorAll(`[data-tor-fx~="${attribToRemove}"]`);
        TORUS.Fx.remove(element, attribToRemove);
      }

      this.attributes_all = this.attributes_all.split(" ").filter( itemToRemove => itemToRemove !== attrib ).join(" ");
    }

    this.element.setAttribute("data-tor-fx-group", this.attributes_all);

    if(this.element.getAttribute("data-tor-fx-group").length === 0) {
      this.element.removeAttribute("data-tor-fx-group");
    }

    this.setAttributes();
    TORUS.Fx.refresh();
  }

  refresh() {
    this.setProperties();
  }

  /**
   * ------------------------------------------------------------------------
   * Public methods
   * ------------------------------------------------------------------------
   */

  /**
   * Add group attributes
   */

  static add(elements, property) {
    initClass({name: "FxGroup", elements: getIterableElement(elements)});
    callFunction({elements: getIterableElement(elements),	argument: optimizeAttribute(property), object: "FxGroup", fn: "add"});
  }

  /**
   * Remove
   */

  static remove(elements, property) {
    callFunction({elements: getIterableElement(elements),	argument: optimizeAttribute(property), object: "FxGroup", fn: "remove" });
  }

  /**
   * Set attributes
   */

  static setAttributes(elements) {
    callFunction({elements: elements, object: "FxGroup", fn: "setAttributes" });
  }

  /**
   * ------------------------------------------------------------------------
   * Init
   * ------------------------------------------------------------------------
   */

  static init(elements) {
    elements = getIterableElement(elements || "[data-tor-fx-group]");
    initClass({name: "FxGroup", elements: elements});

    // this.setAttributes(elements);
    callFunction({elements: elements, object: "FxGroup", fn: "setAttributes" });

    TORUS.FxContainer.init()

    TORUS.Fx.init([...document.querySelectorAll("[data-tor-fx], [data-tor-fx-trigger~='inview']")].filter( item => { return !item.hasTORUSContainer }) );

    // TORUS.Fx.init()

  }
}

// TORUS.FxGroup.init();
onLoad("FxGroup", "DOMContentLoaded");

export default TORUS.FxGroup;