/**
 * ------------------------------------------------------------------------
 * ------------------------------------------------------------------------
 * FX Globals
 * ------------------------------------------------------------------------
 * ------------------------------------------------------------------------
 */

import {
  getStyleValue,
  mousePercents,
  scrollPercents,
  setLiveCSS,
  SCROLLED,
  MOUSE,
  SENSOR,
  WINDOW,
  OBSERVER,
} from "./util";

/**
 * ------------------------------------------------------------------------
 * Variables
 * ------------------------------------------------------------------------
 */

let LAST_MOUSE_X = 0;
let LAST_MOUSE_Y = 0;
let LAST_SENSOR_X = 0;
let LAST_SENSOR_Y = 0;
let LAST_SCROLLED_TOP;
let mouseMoving = 0;
let scrolling = 0;
let sensorMoving = 0;
let scrollFinished;
let mouseFinished;
let sensorFinished;

/**
 * ------------------------------------------------------------------------
 * Predefined values
 * ------------------------------------------------------------------------
 */

const PREDEFINED = getStyleValue(document.body, "--tor-predefined-values").split(",");
// const PREDEFINED = "blue,indigo,purple,pink,red,orange,yellow,green,teal,cyan,white,gray,gray-dark,navy,maroon,brown,magenta,lime,black,primary,secondary,success,info,warning,danger,light,dark,none,xs,sm,md,lg,xl,full,half,0,10,45,90,180,360,25,50,75,125,150,175,200,100,250,300,350,400,450,500,550,600,650,700,750,800,850,900,950,1000,5,20,30,40,60,70,80,risen,pop".split(",");

/**
 * ------------------------------------------------------------------------
 * Global sets
 * ------------------------------------------------------------------------
 */

let SCROLL_ELEMENTS = new Set();
let MOUSE_ELEMENTS  = new Set();
let INVIEW_ELEMENTS  = new Set();
let SENSOR_ELEMENTS  = new Set();
let INTERSECTION_ELEMENTS  = new Set();
let CONTAINERS = new Set();

// let ALL_ELEMENTS = new Set();

/**
 * Custom FX observer
 * Stores all elements with scroll/mouse/sensor action. Will be used in Intersection observer
 * to check if element is in/out of the viewport to start/stop the transition
 */

let PARENT_ELEMENTS = new Set();
let REFRESH_SCROLL_ELEMENTS = new Set();

/**
 * ------------------------------------------------------------------------
 * ------------------------------------------------------------------------
 * Global event handlers
 * ------------------------------------------------------------------------
 * ------------------------------------------------------------------------
 */

/**
 * ------------------------------------------------------------------------
 * On Mouse handle
 * ------------------------------------------------------------------------
 */

 export function ON_MOUSE(e) {
  MOUSE.x = e.clientX;
  MOUSE.y = e.clientY;

  requestAnimationFrame(ON_RAF);
}

/**
 * ------------------------------------------------------------------------
 * On Scroll handle
 * ------------------------------------------------------------------------
 */

export function ON_SCROLL() {
  if(!LAST_SCROLLED_TOP) {
    LAST_SCROLLED_TOP = window.scrollY;
  }

  SCROLLED.top = window.scrollY;

  requestAnimationFrame(ON_RAF);
}

/**
 * ------------------------------------------------------------------------
 * On Sensor handle
 * ------------------------------------------------------------------------
 */

export function ON_SENSOR(e) {
  let sensorXRounded = Math.round(e.beta*5)/5;
  let sensorYRounded = Math.round(e.gamma*5)/5;

  SENSOR.x = Math.round(e.beta*1000)/1000;
  SENSOR.y = Math.round(e.gamma*1000)/1000;

  sensorMoving = (LAST_SENSOR_X - sensorXRounded) + (LAST_SENSOR_Y - sensorYRounded);

  LAST_SENSOR_X = sensorXRounded;
  LAST_SENSOR_Y = sensorYRounded;

  if (sensorMoving) {
    requestAnimationFrame(ON_RAF);
  }
  else {
    cancelAnimationFrame(ON_RAF);
    sensorMoving = false;
  }
}

/**
 * ------------------------------------------------------------------------
 * On requestAnimationFrame
 * ------------------------------------------------------------------------
 */

export function ON_RAF() {
  mouseMoving = (LAST_MOUSE_X - MOUSE.x) + (LAST_MOUSE_Y - MOUSE.y);
  scrolling = LAST_SCROLLED_TOP - SCROLLED.top;

  for (const _this of INTERSECTION_ELEMENTS) {
    OBSERVER("INTERSECTION", _this.element);
  }

  if (mouseMoving) {
    for (const _this of MOUSE_ELEMENTS) {
      MOUSE_ACTIONS(_this);
    }

    LAST_MOUSE_X = MOUSE.x;
    LAST_MOUSE_Y = MOUSE.y;

    mouseFinished = false;
  }
  else {
    if (!mouseFinished) {
      mouseFinished = true;
    }
  }

  if (scrolling) {
    for(const _this of SCROLL_ELEMENTS) {
      SCROLL_ACTIONS(_this);
    }

    for(const _this of MOUSE_ELEMENTS) {
      MOUSE_ACTIONS(_this);
    }

    for(const _this of INVIEW_ELEMENTS) {
      OBSERVER("INVIEW", _this.element);
    }

    LAST_SCROLLED_TOP = SCROLLED.top;
    scrollFinished = false;
  }
  else {
    if(!scrollFinished) {
      for(const _this of [...REFRESH_SCROLL_ELEMENTS]) {
        // TODO: Optimize to prevent reflow
        // _this.element.TORUS.Fx.setElementBounds(_this);
      }
      scrollFinished = true;
    }
  }

  if (sensorMoving) {
    for (const _this of SENSOR_ELEMENTS) {
      SENSOR_ACTIONS(_this);
    }
  }

  if(mouseMoving || scrolling || sensorMoving) {
    window.requestAnimationFrame(ON_RAF);
  }
  else {
    window.cancelAnimationFrame(ON_RAF);
  }

}

/**
 * ------------------------------------------------------------------------
 * ------------------------------------------------------------------------
 * Global event actions
 * ------------------------------------------------------------------------
 * ------------------------------------------------------------------------
 */

/**
 * ------------------------------------------------------------------------
 * Scroll
 * ------------------------------------------------------------------------
 */

export function SCROLL_ACTIONS(_this) {
  let percents;

  if(_this.properties.customTransform.scroll) {
    if(_this.visible) {

      for(let i in _this.properties.customTransform.scroll) {
        let obj = _this.properties.customTransform.scroll;
        let CV = obj[i].currentValues;

        percents = scrollPercents(_this, CV.offsetEnd, CV.offsetStart, CV.afterScrolledStart, CV.afterScrolledEnd)[obj[i].options.behavior].sy;

        if( percents < 0 && !obj[i].options.is_parallax && percents < 0 && !!obj[i].options.is_scroll ) {
          percents = 0;
        }
        if( percents > 1 && !obj[i].options.is_parallax && percents > 1 && !!obj[i].options.is_scroll ) {
          percents = 1;
        }

        /**
         * IF: Multiple values defined by [...value1, value2, valueN]
         */
        if(obj[i].multiValues.enabled) {
          let tempArray = [];
          for(let i=0; i<CV.start.length; i++) {
            if(i < (CV.start.length - 1)) {
              /** Round because some browsers (iOS) cannot handle decimal in some CSS such as rgba() */
              tempArray.push(Math.round(CV.start[i] + ((CV.end[i]-CV.start[i]) * percents)));
            }
            else {
              tempArray.push( (CV.start[i] + ((CV.end[i]-CV.start[i]) * percents)).toFixed(4) );
            }
          }
          _this.properties.customTransform.scroll[i].currentValues.live = tempArray;
        }
        else {
          _this.properties.customTransform.scroll[i].currentValues.live = `${ (CV.start + ((CV.end-CV.start) * percents)).toFixed(4) }${obj[i].unit || ""}`;
        }
      }

      setLiveCSS(_this);

    }
  }
}

/**
 * ------------------------------------------------------------------------
 * Mouse actions
 * ------------------------------------------------------------------------
 */

export function MOUSE_ACTIONS(_this) {
  let percents;

  if(_this.properties.customTransform.mouse) {
    if (_this.visible) {

      for(let i in _this.properties.customTransform.mouse) {
        let obj = _this.properties.customTransform.mouse;
        let CV = obj[i].currentValues;

        switch (obj[i].action) {
          case "mouse":
            percents = mousePercents(_this)[obj[i].options.origin].m;
            break;

          case "mouseX":
            percents = mousePercents(_this)[obj[i].options.origin].mx;
            break;

          case "mouseY":
            percents = mousePercents(_this)[obj[i].options.origin].my;
            break;
        }

        if (obj[i].multiValues.enabled) {
          let tempArray = [];
          for (let i=0; i<CV.start.length; i++) {
            if (i < (CV.start.length - 1)) {
              /** Round because some browsers (iOS) cannot handle decimal in some CSS such as rgba() */
              tempArray.push(Math.round(CV.start[i] + ((CV.end[i]-CV.start[i]) * percents)));
            }
            else {
              tempArray.push( (CV.start[i] + ((CV.end[i]-CV.start[i]) * percents)).toFixed(4) );
            }
          }
          _this.properties.customTransform.mouse[i].currentValues.live = tempArray;
        }
        else {
          _this.properties.customTransform.mouse[i].currentValues.live = `${ (CV.start + ((CV.end-CV.start) * percents) ).toFixed(4) }${obj[i].unit || ""}`;
        }
      }

      setLiveCSS(_this);

    }
  }
}

export function SENSOR_ACTIONS(_this) {
  let percents;

  if(_this.properties.customTransform.sensor) {
    if (_this.visible) {
      for(let i in _this.properties.customTransform.sensor) {
        let obj = _this.properties.customTransform.sensor;
        let CV = obj[i].currentValues;

        switch (obj[i].action) {
          case "sensorX":
            percents = Math.round((SENSOR.x / 90) * 1000) / 1000;
            break;

          case "sensorY":
            percents = Math.round((SENSOR.y / 90) * 1000) / 1000;
            break;
        }

        if(obj[i].multiValues.enabled) {
          let tempArray = [];
          for(let i=0; i<CV.start.length; i++) {
            if(i < (CV.start.length - 1)) {
              /** Round because some browsers (iOS) cannot handle decimal in some CSS such as rgba() */
              tempArray.push(Math.round(CV.start[i] + ((CV.end[i]-CV.start[i]) * percents)));
            }
            else {
              tempArray.push( (CV.start[i] + ((CV.end[i]-CV.start[i]) * percents)).toFixed(4) );
            }
          }
          _this.properties.customTransform.sensor[i].currentValues.live = tempArray;
        }
        else {
          _this.properties.customTransform.sensor[i].currentValues.live = `${ (CV.start + ((CV.end-CV.start) * percents)).toFixed(4) }${obj[i].unit || ""}`;
        }
      }

      setLiveCSS(_this);

    }
  }
}

export {
  PREDEFINED,
  CONTAINERS,
  SCROLL_ELEMENTS,
  MOUSE_ELEMENTS,
  INVIEW_ELEMENTS,
  SENSOR_ELEMENTS,
  INTERSECTION_ELEMENTS,
  PARENT_ELEMENTS,
  REFRESH_SCROLL_ELEMENTS,
}